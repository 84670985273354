@font-face {
  font-family: "TipoTypeHomeBold"; /*Can be any text*/
  src: local("TipoTypeBoldHome"),
    url("../../font/TipoTypeBoldHome.otf") format("opentype"),
    url("../../font/TipoTypeBoldHome.woff") format("woff"),
    url("../../font/TipoTypeBoldHome.svg#webfont") format("svg");
}

@font-face {
  font-family: "TipoTypeHomeRegular"; /*Can be any text*/
  src: local("TipoTypeRegularHome"),
    url("../../font/TipoTypeRegularHome.otf") format("opentype"),
    url("../../font/TipoTypeRegularHome.woff") format("woff");
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// span {
//   font-family: "TipoTypeHomeBold";
// }

// p,
// a {
//   font-family: "TipoTypeHomeRegular";
// }
