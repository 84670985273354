.contactSection {
  width: 100%;

  .contact {
    margin: 100px 0;

    @media only screen and (min-width: 992px) {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 60px;
      margin: 64px 0;
    }

    .leftSide {
      @media only screen and (min-width: 992px) {
        width: 50%;
      }

      .lines {
        img {
          &:nth-child(2) {
            margin-left: -10px;
          }
        }
      }

      h2 {
        margin: 15px 0;

        @media only screen and (min-width: 1100px) {
          width: 500px;
        }

        @media only screen and (min-width: 1300px) {
          width: 590px;
        }
      }

      p {
        @media only screen and (min-width: 1200px) {
          width: 450px;
        }
      }
    }

    .rightSide {
      margin-top: 50px;

      @media only screen and (min-width: 992px) {
        width: 50%;
        margin-top: 0;
      }

      form {
        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.28125px;
          color: #fff;
          margin-left: 20px;
          margin-bottom: 10px;
        }

        input[type="text"],
        input[type="email"],
        textarea {
          width: 100%;
          padding: 16px 20px;
          margin: 8px 0 30px 0;
          display: inline-block;
          background: #16131a;
          border: none;
          font-family: "Exo 2", sans-serif;
          border: 1px solid transparent;
          border-radius: 5px;
          box-sizing: border-box;
          color: #fff;
          outline: none;

          &::placeholder-shown {
            font-family: "Exo 2", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.28125px;
            color: #535353;
          }

          &:focus {
            background: transparent;
            border: 1px solid #ffffff;
            box-shadow: 0px 0px 10px #ffffff;
            border-radius: 5px;
          }

          &:not(:placeholder-shown) {
            background: transparent;
            border: 1px solid #ffffff;
            box-shadow: 0px 0px 4px #ffffff;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
