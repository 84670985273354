@font-face {
  font-family: "Normatica"; /*Can be any text*/
  src: local("Normatica-Bold"),
    url("../../font/Normatica-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NormaticaRegular"; /*Can be any text*/
  src: local("Normatica-Regular"),
    url("../../font/Normatica-Regular.ttf") format("truetype");
}

.winningDisplay {
  background: #000;
  height: 100vh;
  font-family: "Normatica";
  display: flex;
  flex-direction: row;
  gap: 37px;
  align-items: center;
  justify-content: center;
  padding: 0 162px;
  position: relative;

  @media only screen and (max-width: 1700px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 960px) {
    padding: 0 40px;
  }

  .cutLogo {
    position: absolute;
    bottom: 0;
    left: 0;

    @media only screen and (max-width: 1500px) {
      width: 27%;
    }

    @media only screen and (max-width: 960px) {
      left: -15px;
      width: 21%;
    }
  }

  .arenaTitle {
    position: absolute;
    top: 80px;
    z-index: 2;

    @media only screen and (max-width: 1500px) {
      top: 30px;
      width: 15%;
    }

    @media only screen and (max-width: 960px) {
      top: 25px;
    }
  }

  .winner {
    width: 50%;
    border: 2px solid #fff000;
    position: relative;
    margin-top: 30px;

    .dot {
      position: absolute;
      right: -8px;
      bottom: -7px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff000;
      box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 240, 0, 0.7);
      animation: xAxis 10s linear infinite;

      @media only screen and (max-width: 960px) {
        bottom: -8px;
      }

      @keyframes xAxis {
        0% {
          left: 0;
        }
        50% {
          left: 100%;
        }
        100% {
          left: 0;
        }
      }
    }

    .dot1 {
      position: absolute;
      left: -7px;
      bottom: -5px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff000;
      box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 240, 0, 0.7);
      animation: YAxis 10s linear infinite;

      @media only screen and (max-width: 960px) {
        left: -8px;
      }

      @keyframes YAxis {
        0% {
          top: 0;
        }
        50% {
          top: 100%;
        }
        100% {
          top: 0;
        }
      }
    }

    .dot2 {
      position: absolute;
      right: -8px;
      top: -7px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff000;
      box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 240, 0, 0.7);
      animation: xAxis 10s linear infinite;

      @media only screen and (max-width: 960px) {
        top: -8px;
      }

      @keyframes xAxis {
        0% {
          left: 0;
        }
        50% {
          left: 100%;
        }
        100% {
          left: 0;
        }
      }
    }

    .dot3 {
      position: absolute;
      right: -7px;
      bottom: -5px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff000;
      box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 240, 0, 0.7);
      animation: YAxis 10s linear infinite;

      @media only screen and (max-width: 960px) {
        right: -8px;
      }

      @keyframes YAxis {
        0% {
          top: 0;
        }
        50% {
          top: 100%;
        }
        100% {
          top: 0;
        }
      }
    }

    .winnerLogo {
      text-align: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // gap: 15px;
      // padding: 30px 0;
      padding: 20px 0 7px 0;
      gap: 10px;

      @media only screen and (max-width: 960px) {
        padding: 10px 0;
      }

      img {
        // width: 17px;
        // height: 25px;
        display: none;
      }

      h4 {
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        @media only screen and (max-width: 960px) {
          font-size: 30px;
          line-height: 20px;
        }
      }

      .placement {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        h5 {
          display: flex;
          align-items: self-start;
          font-size: 28px;
          text-transform: uppercase;

          @media only screen and (max-width: 960px) {
            font-size: 20px;
          }

          span {
            font-size: 11px !important;
            margin-top: -7px;
          }
        }
      }
    }

    .winnerNameId {
      display: flex;
      border-top: 2px solid #fff000;
      border-bottom: 2px solid #fff000;

      .winnerPoints {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 35px;
        font-weight: bold;
        color: #fff;
        height: fit-content;
        padding: 10px 0;
        margin-top: 5px;

        @media only screen and (max-width: 1500px) {
          font-size: 28px;
        }

        @media only screen and (max-width: 1500px) {
          font-size: 20px;
          margin-top: 0;
        }

        span {
          font-family: NormaticaRegular;
          text-transform: uppercase;
          font-weight: 400;
        }
      }

      .winnerHints {
        width: 34%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 35px;
        font-weight: bold;
        color: #fff;
        border-left: 2px solid #fff000;
        border-right: 2px solid #fff000;
        height: fit-content;
        padding: 10px 0;
        margin-top: 5px;

        @media only screen and (max-width: 1500px) {
          font-size: 28px;
        }

        @media only screen and (max-width: 1500px) {
          font-size: 20px;
          margin-top: 0;
        }

        span {
          font-family: NormaticaRegular;
          text-transform: uppercase;
          font-weight: 400;
        }
      }

      .winnerLeft {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 35px;
        font-weight: bold;
        color: #fff;
        height: fit-content;
        padding: 10px 0;
        margin-top: 5px;

        @media only screen and (max-width: 1500px) {
          font-size: 28px;
        }

        @media only screen and (max-width: 1500px) {
          font-size: 20px;
          margin-top: 0;
        }

        span {
          font-family: NormaticaRegular;
          text-transform: uppercase;
          font-weight: 400;
        }
      }
    }

    .winnerInfo {
      display: flex;
      img {
        // height: 320px;
        height: 390px;
        width: 100%;
        padding: 25px;

        @media only screen and (max-width: 1920px) {
          width: 100%;
          object-fit: cover;
        }

        @media only screen and (min-width: 2000px) {
          height: 100%;
        }

        @media only screen and (max-width: 1500px) {
          width: 90%;
          height: 300px;
        }

        @media only screen and (max-width: 960px) {
          height: 231px;
        }
      }
    }
  }

  .otherTeamsScore {
    position: relative;
    // width: 100%;
    width: 50%;
    height: 660px;
    overflow: hidden;
    overflow-y: hidden;
    margin-top: 10px;

    @media only screen and (max-width: 1500px) {
      height: 529px;
      margin-top: 20px;
    }

    @media only screen and (max-width: 960px) {
      height: 450px;
    }

    .otherTeamsTitle {
      font-family: no;
      color: #fff;
      background: #000;
      z-index: 1;
      position: inherit;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      width: 100%;
      margin-top: -5px;
      padding: 0 10px;
      text-align: center;
      padding-bottom: 15px;
      font-family: "Normatica";

      @media only screen and (max-width: 1500px) {
        font-size: 36px;
      }

      @media only screen and (max-width: 960px) {
        font-size: 30px;
        padding-top: 10px;
      }
    }

    .anim {
      position: absolute;
      width: 100%;
      left: 0%;
      top: 0%;

      .topPlayerCard {
        display: flex;
        border: 2px solid #ff0000;
        height: 68px;
        margin-bottom: 26px;

        @media only screen and (max-width: 1500px) {
          height: 55px;
        }

        @media only screen and (max-width: 960px) {
          height: 40px;
          margin-bottom: 20px;
        }

        .teamRate {
          width: 69px;
          height: 100%;
          border-right: 2px solid #ff0000;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: 0.1em;
          color: #ff0000;

          @media only screen and (max-width: 1700px) {
            width: 10%;
          }

          @media only screen and (max-width: 960px) {
            font-size: 18px;
          }
        }

        .teamName {
          width: 307px;
          height: 100%;
          padding-left: 25px;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: 0.1em;
          color: #ffffff;
          border-right: 2px solid #ff0000;
          display: flex;
          align-items: center;
          text-transform: uppercase;

          @media only screen and (max-width: 1700px) {
            width: 40%;
            font-size: 16px;
          }

          @media only screen and (min-width: 2000px) {
            width: 50%;
          }

          @media only screen and (max-width: 960px) {
            font-size: 12px;
            padding-left: 10px;
          }
        }

        .teamScore {
          width: 397px;
          height: 100%;
          text-align: center;
          color: #fff;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1em;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (max-width: 1700px) {
            width: 50%;
            font-size: 14px;
          }

          @media only screen and (max-width: 1600px) {
            font-size: 12px;
          }

          @media only screen and (max-width: 1400px) {
            font-size: 10px;
          }

          @media only screen and (max-width: 960px) {
            width: 70%;
          }

          span {
            color: #ff0000;
          }
        }

        &:nth-child(2) {
          border: 2px solid #00a2e0;
          .teamRate {
            color: #00a2e0;
            border-right: 2px solid #00a2e0;
          }

          .teamName {
            border-right: 2px solid #00a2e0;
          }

          .teamScore {
            span {
              color: #00a2e0;
            }
          }
        }

        &:nth-child(3) {
          border: 2px solid #c3986b;
          .teamRate {
            color: #c3986b;
            border-right: 2px solid #c3986b;
          }

          .teamName {
            border-right: 2px solid #c3986b;
          }

          .teamScore {
            span {
              color: #c3986b;
            }
          }
        }

        &:nth-child(4) {
          border: 2px solid #652d8f;
          .teamRate {
            color: #652d8f;
            border-right: 2px solid #652d8f;
          }

          .teamName {
            border-right: 2px solid #652d8f;
          }

          .teamScore {
            span {
              color: #652d8f;
            }
          }
        }

        &:nth-child(5) {
          border: 2px solid #ed297b;
          .teamRate {
            color: #ed297b;
            border-right: 2px solid #ed297b;
          }

          .teamName {
            border-right: 2px solid #ed297b;
          }

          .teamScore {
            span {
              color: #ed297b;
            }
          }
        }

        &:nth-child(6) {
          border: 2px solid #ef5a28;
          .teamRate {
            color: #ef5a28;
            border-right: 2px solid #ef5a28;
          }

          .teamName {
            border-right: 2px solid #ef5a28;
          }

          .teamScore {
            span {
              color: #ef5a28;
            }
          }
        }

        &:nth-child(7) {
          border: 2px solid #74cf00;
          .teamRate {
            color: #74cf00;
            border-right: 2px solid #74cf00;
          }

          .teamName {
            border-right: 2px solid #74cf00;
          }

          .teamScore {
            span {
              color: #74cf00;
            }
          }
        }

        &:nth-child(8) {
          border: 2px solid #4f56ff;
          .teamRate {
            color: #4f56ff;
            border-right: 2px solid #4f56ff;
          }

          .teamName {
            border-right: 2px solid #4f56ff;
          }

          .teamScore {
            span {
              color: #4f56ff;
            }
          }
        }

        &:nth-child(9) {
          border: 2px solid #0ae9b3;
          .teamRate {
            color: #0ae9b3;
            border-right: 2px solid #0ae9b3;
          }

          .teamName {
            border-right: 2px solid #0ae9b3;
          }

          .teamScore {
            span {
              color: #0ae9b3;
            }
          }
        }

        &:nth-child(10) {
          border: 2px solid #ff6bb2;
          .teamRate {
            color: #ff6bb2;
            border-right: 2px solid #ff6bb2;
          }

          .teamName {
            border-right: 2px solid #ff6bb2;
          }

          .teamScore {
            span {
              color: #ff6bb2;
            }
          }
        }
      }
    }

    .anim.primary {
      animation: primary 20s linear infinite;
      width: 100%;
      margin-top: 80px;

      @keyframes primary {
        0% {
          top: 0%;
        }
        50% {
          top: -100%;
        }
        75% {
          top: 00%;
        }
        100% {
          top: 0;
        }
      }
    }
  }
}
