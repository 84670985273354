.ss {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  perspective: 360px;
  position: sticky;
  perspective-origin: 50% 50%;
  background-image: url("../../images/v.gif");
  animation-iteration-count: 3;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.aboutSection {
  display: flex;
  align-items: center;
  height: 100vh;

  .aboutExperience {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 1100px) {
      flex-direction: row-reverse;
      align-items: center;
    }

    .aboutImage {
      margin-bottom: 45px;

      // @keyframes glow {
      //   100% {
      //     border: 2px solid #ffffff;
      //     box-shadow: 0px 0px 9px 4px #462cff, 0px 0px 9px 4px #462cff,
      //       inset -1px 2px 9px 4px #462cff;
      //   }
      // }

      @media only screen and (min-width: 1100px) {
        margin-left: -20%;
        margin-bottom: 0;
      }

      img {
        width: 100%;

        @media only screen and (min-width: 1100px) {
          width: 500px;
        }

        @media only screen and (min-width: 1600px) {
          width: 750px;
        }
      }
    }

    .aboutContent {
      .aboutLine {
        border-bottom: 2px solid #ffffff;
        box-shadow: 0px 0px 4px 3px #462cff;
        width: 155px;
        margin-top: 5px;
        margin-bottom: 30px;
        animation: 4s infinite line;

        @keyframes line {
          100% {
            border-bottom: 2px solid #ffffff;
            box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
              inset -1px 2px 9px 4px #cf004b;
          }
        }
      }
    }
  }
}
