@import "../../style/global.scss";

.navigation {
  position: fixed;
  top: 40px;
  width: 100%;
  z-index: 1;

  .menu {
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      margin-left: -10px;

      @media only screen and (min-width: 992px) {
        margin-left: 0;
      }
    }

    @media only screen and (min-width: 992px) {
      background: rgba(0, 0, 0, 0.3);
      height: 87px;
      margin-left: 60px;
      margin-right: 60px;
      padding: 0px 60px;
    }

    .hamburgerMenu {
      @media only screen and (min-width: 992px) {
        display: none;
      }
    }

    .menuBackground {
      background: rgba(0, 0, 0, 0.8);
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    .mobileContentMenu {
      position: fixed;
      right: 0;
      top: 0px;
      background: url("../../images/mobileMenuBackground.png");
      background-position-x: center;
      background-position-y: center;
      background-size: cover;
      height: 100%;
      width: 100%;

      .mobileMenuHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        padding: 0 25px;

        .mobileTitle {
          h3 {
            font-weight: 600;
          }
        }

        .closeMenu {
          svg {
            cursor: pointer;
          }
        }
      }

      .menuItems {
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;

        ul {
          list-style-type: none;
          text-align: center;

          li {
            margin-bottom: 35px;

            &:nth-child(3) {
              margin-bottom: 50px;
            }
            a {
              font-weight: 500;
              font-size: 21px;
              line-height: 28px;
              text-transform: uppercase;
              text-decoration: none;
              color: #fff;
            }
          }
        }
      }
    }

    .list {
      display: none;

      @media only screen and (min-width: 992px) {
        display: flex;
      }

      ul {
        display: flex;
        align-items: center;
        gap: 35px;
        list-style-type: none;

        li {
          &:nth-child(3) {
            margin-right: 20px;
          }

          a {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: $gray;
            text-transform: uppercase;
            text-decoration: none;

            &:hover {
              color: #ffffff;
              text-shadow: 0px 0px 4px #ffffff;
            }
          }
        }
      }
    }
  }
}
