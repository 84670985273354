.forgotPasswordScreen {
  background-image: url("../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: fixed;

  @media only screen and (max-width: 1200px) {
    height: 100%;
  }

  .welcomeScreenBtnDisable {
    opacity: 0.5;
  }

  .roomPopup {
    .matchContent {
      box-shadow: 0px 0px 9px 4px #fff, 0px 0px 9px 4px #fff,
        inset 1px 2px 4px 0px #fff !important;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .formContent {
      margin-top: 50px;
      padding: 40px;
      background-color: transparent;
      border: 1px solid #ffffff;
      box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
        inset -3px 4px 9px 0px #cf004b;
      border-radius: 5px;

      @media only screen and (max-width: 1200px) {
        padding: 20px 10px;
        width: 85%;
      }

      .innerContent {
        width: 550px;

        @media only screen and (max-width: 1200px) {
          width: 100%;
        }

        h3 {
          text-align: center;

          @media only screen and (max-width: 1200px) {
            margin-top: 20px;
          }
        }

        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.28125px;
          color: #fff;
          margin-left: 20px;
          margin-bottom: 10px;
        }

        input[type="text"],
        input[type="password"],
        input[type="time"] {
          font-size: 14px;
          width: 100%;
          padding: 16px 20px;
          margin: 8px 0 0px 0;
          display: inline-block;
          background: #16131a;
          border: none;
          font-family: "Exo 2", sans-serif;
          border: 1px solid transparent;
          border-radius: 5px;
          box-sizing: border-box;
          color: #fff;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          &.time {
            width: 25%;

            @media only screen and (min-width: 360px) {
              width: 30%;
            }
          }

          &::placeholder-shown {
            font-family: "Exo 2", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.28125px;
            color: #535353;
          }

          &::-webkit-calendar-picker-indicator {
            display: none;

            @media only screen and (min-width: 1200px) {
              display: block !important;
              filter: invert(1);
            }
          }

          &:focus {
            background: transparent;
            border: 1px solid #ffffff;
            box-shadow: 0px 0px 10px #ffffff;
            border-radius: 5px;
          }

          &:not(:placeholder-shown) {
            border: 1px solid transparent;
            border-radius: 5px;
            height: 50px;

            &:focus {
              border: 1px solid #ffffff;
              box-shadow: 0px 0px 10px #ffffff;
            }
          }
        }

        .passwordIcon {
          position: absolute;
          top: 45px;
          right: 20px;
          width: 16px;
          height: 16px;
          cursor: pointer;
        }

        .forgotPassword {
          float: right;
          color: #fff;
          font-size: 14px;
          margin-top: 10px;
          text-decoration: underline;
          cursor: pointer;
        }

        .errorMessage {
          text-align: left;
          color: #cf004b;
          font-size: 12px;
          margin: 0;
        }

        .form {
          display: flex;
          flex-direction: column;
          gap: 40px;
          margin: 50px 0 20px;

          @media only screen and (max-width: 1200px) {
            margin-top: 20px;
          }
        }

        .loaderState {
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
          }
        }

        p {
          color: #fff;
          text-align: center;
          margin: 20px 0 25px;

          span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    .loadFormContent {
      margin-top: 50px;
      padding: 40px;
      background-color: transparent;
      border: 1px solid #ffffff;
      box-shadow: 0px 0px 9px 4px #fff, 0px 0px 9px 4px #fff,
        inset 1px 2px 4px 0px #fff !important;
      border-radius: 5px;

      @media only screen and (max-width: 1200px) {
        padding: 20px 10px;
        width: 85%;
      }

      .innerContent {
        width: 550px;

        @media only screen and (max-width: 1200px) {
          width: 100%;
        }

        h3 {
          text-align: center;

          @media only screen and (max-width: 1200px) {
            margin-top: 20px;
          }
        }

        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.28125px;
          color: #fff;
          margin-left: 20px;
          margin-bottom: 10px;
        }

        input[type="text"],
        input[type="password"],
        input[type="time"] {
          font-size: 14px;
          width: 100%;
          padding: 16px 20px;
          margin: 8px 0 0px 0;
          display: inline-block;
          background: #16131a;
          border: none;
          font-family: "Exo 2", sans-serif;
          border: 1px solid transparent;
          border-radius: 5px;
          box-sizing: border-box;
          color: #fff;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          &.time {
            width: 25%;

            @media only screen and (min-width: 360px) {
              width: 30%;
            }
          }

          &::placeholder-shown {
            font-family: "Exo 2", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.28125px;
            color: #535353;
          }

          &::-webkit-calendar-picker-indicator {
            display: none;

            @media only screen and (min-width: 1200px) {
              display: block !important;
              filter: invert(1);
            }
          }

          &:focus {
            background: transparent;
            border: 1px solid #ffffff;
            box-shadow: 0px 0px 10px #ffffff;
            border-radius: 5px;
          }

          &:not(:placeholder-shown) {
            border: 1px solid transparent;
            border-radius: 5px;
            height: 50px;

            &:focus {
              border: 1px solid #ffffff;
              box-shadow: 0px 0px 10px #ffffff;
            }
          }
        }

        .passwordIcon {
          position: absolute;
          top: 45px;
          right: 20px;
          width: 16px;
          height: 16px;
          cursor: pointer;
        }

        .forgotPassword {
          float: right;
          color: #fff;
          font-size: 14px;
          margin-top: 10px;
          text-decoration: underline;
          cursor: pointer;
        }

        .errorMessage {
          text-align: left;
          color: #cf004b;
          font-size: 12px;
          margin: 0;
        }

        .form {
          display: flex;
          flex-direction: column;
          gap: 40px;
          margin: 50px 0 20px;

          @media only screen and (max-width: 1200px) {
            margin-top: 20px;
          }
        }

        .loaderState {
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
          }
        }

        p {
          color: #fff;
          text-align: center;
          margin: 20px 0 25px;

          span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
