.welcomeScreen {
  background-image: url("../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .pageLogo {
    position: absolute;
    top: 40px;
  }

  h2 {
    text-align: center;
    padding: 0 20px;
  }
}
