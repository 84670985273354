@font-face {
  font-family: "Normatica"; /*Can be any text*/
  src: local("Normatica-Bold"),
    url("../../font/Normatica-Bold.ttf") format("truetype");
}

.inGame {
  background: #000;
  height: 100vh;
  // overflow-y: scroll;
  overflow-y: hidden;
  font-family: "Normatica";

  .inGameLogo {
    width: 50%;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: blink linear 5s infinite;
  }

  .inGameContent {
    // padding: 82px 95px;
    padding: 82px 80px;
    text-align: center;

    .inLogo {
      width: 30%;
      margin-bottom: 20px;

      @media only screen and (min-width: 1920px) {
        margin-bottom: 40px;
      }
    }

    @media only screen and (max-width: 1800px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @media only screen and (max-width: 1500px) {
      padding: 20px;
    }

    @media only screen and (max-width: 960px) {
      padding: 20px;
    }

    @media only screen and (min-width: 1920px) {
      width: 1760px;
      margin: auto;
      padding: 40px;
    }

    .topBoard {
      ul {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #6e6f71;
        border-top: 2px solid #6d6e70;
        // margin-bottom: 67px;
        // margin-bottom: 40px;
        margin-bottom: 15px;

        @media only screen and (min-width: 1440px) {
          margin-bottom: 40px;
        }

        li {
          // padding: 22px 0;
          height: 47px;
          border-right: 2px solid #6d6e70;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (max-width: 1500px) {
            font-size: 14px;
          }

          @media only screen and (max-width: 960px) {
            font-size: 9px;
            height: 30px;
          }

          &:nth-child(1) {
            width: 262px;

            @media only screen and (max-width: 1500px) {
              width: 244px;
            }
          }

          &:nth-child(2) {
            width: 305px;

            @media only screen and (max-width: 1500px) {
              width: 238px;
            }
          }

          &:nth-child(3) {
            width: 257px;
          }

          &:nth-child(4) {
            // width: 186px;
            width: 180px;
          }

          &:nth-child(5) {
            // width: 325px;
            width: 324px;
          }

          &:nth-child(6) {
            width: 201px;
          }

          &:last-child {
            width: 192px;
            border-right: none;
          }
        }
      }
    }

    .teamsInGame {
      overflow-y: hidden;
      position: relative;
      width: 100%;
      text-transform: uppercase;

      @media only screen and (min-width: 1920px) {
        width: 1760px;
        margin: auto;
      }

      .anim {
        animation: slide 10s linear infinite;
        width: 100%;
        position: absolute;
        width: 100%;
        left: 0%;
        top: 0%;
      }

      @keyframes slide {
        0% {
          transform: translate3d(0, 10px, 0);
        }
        50% {
          transform: translate3d(0, -230px, 0);
        }
        100% {
          transform: translate3d(0, 10px, 0);
        }
      }

      .animi {
        animation: slide 10s linear infinite;
        width: 100%;
        position: absolute;
        width: 100%;
        left: 0%;
        top: 0%;
        animation-play-state: paused;
      }

      .anims {
        animation: slide 10s linear infinite;
        width: 100%;
        position: absolute;
        width: 100%;
        left: 0%;
        top: 0%;
        animation-play-state: running;
      }

      .specificTeam {
        height: 150px;
        margin-bottom: 55px;
        border: 2px solid #ff0000;
        display: flex;
        position: relative;

        @media only screen and (max-width: 1920px) {
          margin-bottom: 30px;
        }

        @media only screen and (max-width: 1500px) {
          height: 122px;
          margin-bottom: 20px;
        }

        @media only screen and (max-width: 960px) {
          height: 90px;
          margin-bottom: 10px;
        }

        .starginPlayer {
          width: 220px;
          height: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          border-right: 2px solid #ff0000;

          @media only screen and (max-width: 1500px) {
            height: auto;
          }

          @media only screen and (min-width: 2000px) {
            width: 260px;
          }

          span {
            width: 50%;
            height: 100%;
            text-align: center;
            background: #ff0000;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 64px;
            line-height: 70px;
            color: #ffffff;
            position: absolute;
            width: 40px;
            height: 40px;
            font-size: 24px;
            top: 0;
            left: 0;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .wraperProgress {
          display: flex;
          position: relative;

          @media only screen and (min-width: 1921px) {
            width: 100%;
          }

          @media only screen and (max-width: 1800px) {
            width: 85%;
          }

          .teamName {
            width: 306px;
            border-right: 2px solid #ff0000;
            text-align: center;
            padding-top: 39px;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.1em;
            color: #ffffff;

            @media only screen and (max-width: 1500px) {
              padding-top: 30px;
              width: 229px;
            }

            @media only screen and (max-width: 1300px) {
              padding-top: 20px;
            }

            @media only screen and (max-width: 960px) {
              padding-top: 15px;
            }

            h5 {
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.1em;
              color: #ffffff;
              margin-bottom: 4px;

              @media only screen and (max-width: 1500px) {
                font-size: 14px;
              }
            }

            h6 {
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.1em;
              color: #fff;

              @media only screen and (max-width: 1500px) {
                font-size: 14px;
              }

              @media only screen and (max-width: 960px) {
                font-size: 12px;
                line-height: 15px;
              }
            }
          }

          .companyAndRoom {
            width: 250px;
            text-align: center;
            padding-top: 39px;
            border-right: 2px solid #ff0000;

            @media only screen and (max-width: 1500px) {
              padding-top: 30px;
              width: 245px;
            }

            @media only screen and (max-width: 1300px) {
              padding-top: 20px;
            }

            @media only screen and (max-width: 960px) {
              padding-top: 15px;
            }

            h5 {
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.1em;
              color: #ffffff;
              margin-bottom: 4px;

              @media only screen and (max-width: 1500px) {
                font-size: 14px;
              }

              @media only screen and (max-width: 960px) {
                font-size: 12px;
                line-height: 13px;
              }

              &.companyAndRoomResize {
                font-size: 12px;

                @media only screen and (min-width: 1300px) {
                  font-size: 16px;
                }
              }
            }

            h6 {
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.1em;
              color: #ff0000;

              @media only screen and (max-width: 1500px) {
                font-size: 14px;
              }

              @media only screen and (max-width: 960px) {
                font-size: 11px;
                line-height: 9px;
              }
            }
          }

          .teamCity {
            width: 187px;
            text-align: center;
            padding-top: 39px;
            border-right: 2px solid #ff0000;

            @media only screen and (max-width: 1500px) {
              padding-top: 30px;
              width: 173px;
            }

            @media only screen and (max-width: 1300px) {
              padding-top: 20px;
              width: 173px;
            }

            @media only screen and (max-width: 960px) {
              padding-top: 15px;
            }

            h6 {
              font-size: 16px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 0.1em;
              color: #ffffff;
              width: 60%;
              margin: auto;

              @media only screen and (max-width: 1500px) {
                font-size: 14px;
              }

              @media only screen and (max-width: 960px) {
                font-size: 12px;
                line-height: 15px;
              }
            }
          }

          .givenHints {
            width: 325px;
            padding-top: 39px;
            border-right: 2px solid #ff0000;
            text-align: center;
            display: flex;
            justify-content: center;
            // gap: 15px;
            gap: 10px;
            position: relative;

            @media only screen and (max-width: 1500px) {
              padding-top: 30px;
              width: 311px !important;
            }

            @media only screen and (max-width: 960px) {
              padding-top: 15px;
            }

            .inactive {
              svg {
                path {
                  stroke: #ff0000;
                }

                @media only screen and (max-width: 1600px) {
                  width: 40px !important;
                  height: 40px !important;
                  display: block;
                }

                @media only screen and (max-width: 960px) {
                  width: 29px !important;
                  height: 30px !important;
                }

                @media only screen and (min-width: 1600px) {
                  width: 44px !important;
                  height: 49px !important;
                  display: block;
                }
              }
            }

            .active {
              svg {
                .change {
                  stroke: #ff0000;
                  fill: #ff0000;
                }

                @media only screen and (max-width: 1600px) {
                  width: 40px !important;
                  height: 40px !important;
                  display: block;
                }

                @media only screen and (max-width: 960px) {
                  width: 29px !important;
                  height: 30px !important;
                }

                @media only screen and (min-width: 1600px) {
                  width: 44px !important;
                  height: 49px !important;
                  display: block;
                }
              }
            }

            @media only screen and (max-width: 1800px) {
              width: 300px;
              gap: 8px;
            }

            @media only screen and (max-width: 1700px) {
              width: 320px;
            }

            @media only screen and (max-width: 1500px) {
              gap: 5px;
            }

            .aditionalHints {
              position: absolute;
              color: #fff;
              right: 15px;
              top: 25px;
              font-size: 14px;

              @media only screen and (max-width: 960px) {
                right: 4px;
                top: 6px;
                font-size: 9px;
              }
            }

            .hintWrapp {
              position: relative;
            }

            .imgShake {
              content: url("../../images/light.gif");
              position: absolute;
              top: -10px;
              left: -10px;
              width: 150%;
              -webkit-animation: cssAnimation 10s forwards;
              animation: cssAnimation 10s forwards;
            }

            @keyframes cssAnimation {
              0% {
                opacity: 1;
              }
              90% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
            @-webkit-keyframes cssAnimation {
              0% {
                opacity: 1;
              }
              90% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
            .shake {
              position: relative;
              animation: blink linear 1s;
            }

            @keyframes blink {
              78% {
                opacity: 0.2;
              }
              79% {
                opacity: 0.3;
              }
              80% {
                opacity: 1;
              }
              81% {
                opacity: 0.3;
              }
              82% {
                opacity: 0.5;
              }
              83% {
                opacity: 0.2;
              }
              92% {
                opacity: 0.5;
              }
              94% {
                opacity: 0.2;
              }
              95% {
                opacity: 0.5;
              }
              100% {
                opacity: 1;
              }
            }
          }

          .timeLeft {
            width: 201px;
            border-right: 2px solid #ff0000;
            padding-top: 20px;

            @media only screen and (max-width: 1500px) {
              padding-top: 20px;
              width: 195px;
            }

            @media only screen and (max-width: 960px) {
              width: 115px;
              padding-top: 10px;
            }

            .time {
              margin: auto;
              margin-left: 16px;
              margin-right: 16px;
              height: 45px;
              background: #ff0000;
              font-size: 48px;
              line-height: 50px;
              color: #ffffff;
              padding: 16px;
              text-align: center;
              -webkit-text-stroke-width: 1px;
              -webkit-text-stroke-color: black;

              @media only screen and (max-width: 1500px) {
                font-size: 30px;
                padding: 7px;
              }

              @media only screen and (max-width: 960px) {
                font-size: 25px;
                height: 30px;
                width: 66px;
                line-height: 35px;
              }
            }
          }

          .points {
            padding-top: 36px;
            width: 191px;
            text-align: center;
            font-size: 48px;
            line-height: 50px;
            color: #ff0000;

            @media only screen and (max-width: 1500px) {
              padding-top: 25px;
              font-size: 40px;
              width: 180px;
            }

            @media only screen and (max-width: 960px) {
              padding-top: 10px;
              font-size: 40px;
            }
          }

          .progresses {
            position: absolute;
            left: 22px;
            padding-top: 12px;
            padding-bottom: 15px;
            bottom: 0;
            background: #000;
            width: 97%;

            @media only screen and (max-width: 960px) {
              width: 95%;
              padding-top: 7px;
              padding-bottom: 10px;
            }

            progress {
              width: 100%;
              height: 14px;
              background: #ff0000;

              @media only screen and (max-width: 960px) {
                height: 12px;
              }

              &::-webkit-progress-value {
                background: #ff0000;
                transition: all 1s cubic-bezier(0.47, 1.64, 0.41, 0.8);
              }
            }

            .progress-dot {
              left: 41%;
              position: absolute;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              background: #ff0000;
              top: 9px;
              transition: all 1s cubic-bezier(0.47, 1.64, 0.41, 0.8);
              animation: glowing 1s ease-in-out infinite;

              @media only screen and (max-width: 960px) {
                width: 20px;
                height: 20px;
              }
            }
          }

          .glow {
            progress {
              &::-webkit-progress-value {
                box-shadow: 0 0 5px #ff0000, 0 0 10px #ff0000;
                background: #ff0000 url("../../images/new.gif");
                background-size: inherit;
                background-position-y: center;
              }
            }
          }

          @keyframes glowing {
            0% {
              box-shadow: 0 0 15px #ff0000;
              // background-image: url("../../images/dot.gif");
              // background-position: center;
              // background-size: cover;
            }
            50% {
              box-shadow: 0 0 20px #ff0000, 0 0 30px #ff0000;
              background-image: url("../../images/dot.gif");
              background-position: center;
              background-size: inherit;
            }
            100% {
              box-shadow: 0 0 15px #ff0000;
            }
          }
        }

        &:nth-child(2) {
          border: 2px solid #00a2e0;

          .starginPlayer {
            border-right: 2px solid #00a2e0;

            span {
              background: #00a2e0;
            }
          }

          .teamName {
            border-right: 2px solid #00a2e0;
          }

          .companyAndRoom {
            border-right: 2px solid #00a2e0;
            h6 {
              color: #00a2e0;
            }
          }

          .teamCity {
            border-right: 2px solid #00a2e0;
          }

          .givenHints {
            border-right: 2px solid #00a2e0;

            .inactive {
              svg {
                path {
                  stroke: #00a2e0;
                }
              }
            }

            .active {
              svg {
                .change {
                  stroke: #00a2e0;
                  fill: #00a2e0;
                }
              }
            }

            .svgIcon {
              width: 40px !important;
            }
          }

          .timeLeft {
            border-right: 2px solid #00a2e0;

            .time {
              -webkit-text-stroke-width: 1px;
              -webkit-text-stroke-color: black;
              background: #00a2e0;
            }
          }

          .points {
            color: #00a2e0;
          }

          .progresses {
            progress {
              background: #00a2e0;

              &::-webkit-progress-value {
                box-shadow: 0 0 5px #00a2e0, 0 0 10px #00a2e0;
                background: #00a2e0 url("../../images/new.gif");
                background-size: inherit;
                background-position-y: center;
              }
            }

            .progress-dot {
              left: 41%;
              position: absolute;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              background: #00a2e0;
              top: 9px;
              transition: all 1s cubic-bezier(0.47, 1.64, 0.41, 0.8);
              animation: glowingblue 1s ease-in-out infinite;

              @media only screen and (max-width: 960px) {
                width: 20px;
                height: 20px;
              }
            }

            @keyframes glowingblue {
              0% {
                box-shadow: 0 0 15px #00a2e0;
                // background-image: url("../../images/dot.gif");
                // background-position: center;
                // background-size: cover;
              }
              50% {
                box-shadow: 0 0 20px #00a2e0, 0 0 30px #00a2e0;
                background-image: url("../../images/dot.gif");
                background-position: center;
                background-size: inherit;
              }
              100% {
                box-shadow: 0 0 15px #00a2e0;
              }
            }
          }
        }

        &:nth-child(3) {
          border: 2px solid #fff000;

          .starginPlayer {
            border-right: 2px solid #fff000;

            span {
              background: #fff000;
            }
          }

          .teamName {
            border-right: 2px solid #fff000;
          }

          .companyAndRoom {
            border-right: 2px solid #fff000;
            h6 {
              color: #fff000;
            }
          }

          .teamCity {
            border-right: 2px solid #fff000;
          }

          .givenHints {
            border-right: 2px solid #fff000;

            .inactive {
              svg {
                path {
                  stroke: #fff000;
                }
              }
            }

            .active {
              svg {
                .change {
                  stroke: #fff000;
                  fill: #fff000;
                }
              }
            }
          }

          .timeLeft {
            border-right: 2px solid #fff000;

            .time {
              -webkit-text-stroke-width: 1px;
              -webkit-text-stroke-color: black;
              background: #fff000;
            }
          }

          .points {
            color: #fff000;
          }

          .progresses {
            progress {
              background: #fff000;

              &::-webkit-progress-value {
                box-shadow: 0 0 5px #fff000, 0 0 10px #fff000;
                background: #fff000 url("../../images/new.gif");
                background-size: inherit;
                background-position-y: center;
              }
            }

            .progress-dot {
              left: 41%;
              position: absolute;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              background: #fff000;
              top: 9px;
              transition: all 1s cubic-bezier(0.47, 1.64, 0.41, 0.8);
              animation: glowingorange 1s ease-in-out infinite;

              @media only screen and (max-width: 960px) {
                width: 20px;
                height: 20px;
              }
            }

            @keyframes glowingorange {
              0% {
                box-shadow: 0 0 15px #fff000;
                // background-image: url("../../images/dot.gif");
                // background-position: center;
                // background-size: cover;
              }
              50% {
                box-shadow: 0 0 20px #fff000, 0 0 30px #fff000;
                background-image: url("../../images/dot.gif");
                background-position: center;
                background-size: inherit;
              }
              100% {
                box-shadow: 0 0 15px #fff000;
              }
            }
          }
        }

        &:nth-child(4) {
          border: 2px solid #74cf00;

          .starginPlayer {
            border-right: 2px solid #74cf00;

            span {
              background: #74cf00;
            }
          }

          .teamName {
            border-right: 2px solid #74cf00;
          }

          .companyAndRoom {
            border-right: 2px solid #74cf00;
            h6 {
              color: #74cf00;
            }
          }

          .teamCity {
            border-right: 2px solid #74cf00;
          }

          .givenHints {
            border-right: 2px solid #74cf00;

            .inactive {
              svg {
                path {
                  stroke: #74cf00;
                }
              }
            }

            .active {
              svg {
                .change {
                  stroke: #74cf00;
                  fill: #74cf00;
                }
              }
            }
          }

          .timeLeft {
            border-right: 2px solid #74cf00;

            .time {
              -webkit-text-stroke-width: 1px;
              -webkit-text-stroke-color: black;
              background: #74cf00;
            }
          }

          .points {
            color: #74cf00;
          }

          .progresses {
            progress {
              background: #74cf00;

              &::-webkit-progress-value {
                box-shadow: 0 0 5px #74cf00, 0 0 10px #74cf00;
                background: #74cf00 url("../../images/new.gif");
                background-size: inherit;
                background-position-y: center;
              }
            }

            .progress-dot {
              left: 41%;
              position: absolute;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              background: #74cf00;
              top: 9px;
              transition: all 1s cubic-bezier(0.47, 1.64, 0.41, 0.8);
              animation: glowinggreen 1s ease-in-out infinite;

              @media only screen and (max-width: 960px) {
                width: 20px;
                height: 20px;
              }
            }

            @keyframes glowinggreen {
              0% {
                box-shadow: 0 0 15px #74cf00;
                // background-image: url("../../images/dot.gif");
                // background-position: center;
                // background-size: cover;
              }
              50% {
                box-shadow: 0 0 20px #74cf00, 0 0 30px #74cf00;
                background-image: url("../../images/dot.gif");
                background-position: center;
                background-size: inherit;
              }
              100% {
                box-shadow: 0 0 15px #74cf00;
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .teamsGame {
      height: 800px;
    }
  }

  .topWrapper {
    h4 {
      text-align: center;
      margin-bottom: 10px;
      text-align: center;
      text-transform: uppercase;
      margin-top: 20px;

      // @media only screen and (max-width: 1500px) {
      //   margin-top: -10px;
      // }

      @media only screen and (max-width: 960px) {
        margin-bottom: 10px;
        font-size: 20px;
        margin-top: 10px;
      }

      @media only screen and (min-width: 1920px) {
        margin-top: 40px;
        margin-bottom: 30px;
      }
    }
  }

  .topPlayers {
    // margin: 138px 76px 140px;
    margin: 0px 76px 140px;
    display: flex;
    flex-direction: row;
    gap: 29px;

    @media only screen and (min-width: 1920px) {
      width: 1768px;
      margin: auto;
    }

    @media only screen and (max-width: 1500px) {
      margin-left: 45px;
      margin-right: 45px;
    }

    @media only screen and (max-width: 960px) {
      margin-left: 25px;
      margin-right: 25px;
    }

    .leftSide {
      width: 50%;

      .topPlayerCard {
        display: flex;
        border: 2px solid #6d6e70;
        // height: 68px;
        margin-bottom: 26px;
        height: 50px;
        @media only screen and (max-width: 1500px) {
          margin-bottom: 15px;
          height: 50px;
        }

        @media only screen and (max-width: 960px) {
          margin-bottom: 10px;
          height: 35px;
        }

        .teamRate {
          width: 69px;
          height: 100%;
          border-right: 2px solid #6d6e70;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: 0.1em;
          color: #fff000;

          @media only screen and (max-width: 1550px) {
            width: 10%;
          }

          @media only screen and (max-width: 960px) {
            font-size: 16px;
          }
        }

        .teamName {
          width: 307px;
          height: 100%;
          padding-left: 25px;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: 0.1em;
          color: #ffffff;
          border-right: 2px solid #6d6e70;
          display: flex;
          align-items: center;
          text-transform: uppercase;

          @media only screen and (max-width: 1550px) {
            width: 40%;
            font-size: 14px;
            margin: auto;
          }

          @media only screen and (max-width: 960px) {
            width: 62%;
            font-size: 12px;
            margin: auto;
            padding-left: 10px;
          }
        }

        .teamScore {
          width: 397px;
          height: 100%;
          text-align: center;
          color: #fff;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1em;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (max-width: 1500px) {
            width: 70%;
            font-size: 12px;
            margin: auto;
          }

          @media only screen and (max-width: 960px) {
            width: 100%;
            font-size: 10px;
            margin: auto;
          }

          span {
            color: #fff000;
          }
        }

        &:nth-child(2) {
          .teamRate {
            color: #74cf00;
          }

          .teamScore {
            span {
              color: #74cf00;
            }
          }
        }

        &:nth-child(3) {
          .teamRate {
            color: #ff0000;
          }

          .teamScore {
            span {
              color: #ff0000;
            }
          }
        }

        &:nth-child(4) {
          .teamRate {
            color: #00a2e0;
          }

          .teamScore {
            span {
              color: #00a2e0;
            }
          }
        }

        &:nth-child(5) {
          .teamRate {
            color: #c3986b;
          }

          .teamScore {
            span {
              color: #c3986b;
            }
          }
        }
      }
    }

    .rightSide {
      width: 50%;

      .topPlayerCard {
        display: flex;
        border: 2px solid #6d6e70;
        // height: 68px;
        margin-bottom: 26px;
        height: 50px;

        @media only screen and (max-width: 1500px) {
          margin-bottom: 15px;
        }

        @media only screen and (max-width: 960px) {
          margin-bottom: 10px;
          height: 35px;
        }

        .teamRate {
          width: 69px;
          height: 100%;
          border-right: 2px solid #6d6e70;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: 0.1em;
          color: #652d8f;

          @media only screen and (max-width: 1550px) {
            width: 10%;
          }

          @media only screen and (max-width: 960px) {
            font-size: 16px;
          }
        }

        .teamName {
          width: 307px;
          height: 100%;
          padding-left: 25px;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: 0.1em;
          color: #ffffff;
          border-right: 2px solid #6d6e70;
          display: flex;
          align-items: center;
          text-transform: uppercase;

          @media only screen and (min-width: 1921px) {
            width: 27%;
          }

          @media only screen and (max-width: 1550px) {
            width: 40%;
            font-size: 14px;
            margin: auto;
          }

          @media only screen and (max-width: 960px) {
            width: 62%;
            font-size: 12px;
            margin: auto;
            padding-left: 10px;
          }
        }

        .teamScore {
          width: 397px;
          height: 100%;
          text-align: center;
          color: #fff;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1em;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (max-width: 1500px) {
            width: 70%;
            font-size: 12px;
            margin: auto;
          }

          @media only screen and (max-width: 960px) {
            width: 100%;
            font-size: 10px;
            margin: auto;
          }

          span {
            color: #652d8f;
          }
        }

        &:nth-child(2) {
          .teamRate {
            color: #ed297b;
          }

          .teamScore {
            span {
              color: #ed297b;
            }
          }
        }

        &:nth-child(3) {
          .teamRate {
            color: #ef5a28;
          }

          .teamScore {
            span {
              color: #ef5a28;
            }
          }
        }

        &:nth-child(4) {
          .teamRate {
            color: #0ae9b3;
          }

          .teamScore {
            span {
              color: #0ae9b3;
            }
          }
        }

        &:nth-child(5) {
          .teamRate {
            color: #4f56ff;
          }

          .teamScore {
            span {
              color: #4f56ff;
            }
          }
        }
      }
    }
  }
}
