.faq {
  position: relative;

  .contactFaqLines {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;

    @media only screen and (max-width: 1200px) {
      top: -100%;
      right: 0;
      width: 50%;
    }
  }

  .image {
    width: 100%;
    margin-bottom: -10px;
    z-index: 9;
    position: relative;
    background: #000;

    @media only screen and (min-width: 1200px) {
      position: relative;
      z-index: 0;
      width: 80%;
      z-index: 9;
      background: transparent;
    }

    @media only screen and (min-width: 1600px) {
      // margin-bottom: 150px;
    }
  }

  .faqcontainer {
    margin: 0 27px;
    position: relative;
    @media only screen and (min-width: 1200px) {
      margin: 0 250px 0 100px;
    }

    @media only screen and (min-width: 1800px) {
      margin: 0 400px 0 190px;
    }

    .line {
      position: absolute;
      left: 10%;
      height: 1000px;

      @media only screen and (max-width: 1200px) {
        display: none;
      }

      @media only screen and (min-width: 1600px) {
        height: 1300px;
      }

      @media only screen and (min-width: 1800px) {
        height: 1400px;
        left: 8%;
      }
    }

    .redLine {
      width: 100%;
      height: 10px;
      background-color: #ff0000;
      margin-top: -3px;

      @media only screen and (min-width: 1400px) {
        width: 100%;
        height: 15px;
        margin-left: -120px;
      }
    }

    .title {
      background: #ffffff;
      border: 0.813745px solid #000000;
      padding: 25px 13px;
      width: 187px;
      position: absolute;
      top: -30px;
      left: 26px;
      margin-bottom: 30px;
      @media only screen and (min-width: 992px) {
        top: -55px;
        width: 650px;
        padding: 40px 0;
      }

      @media only screen and (min-width: 1200px) {
        padding: 40px 0;
        top: -55px;
        left: 48px;
      }

      @media only screen and (min-width: 1400px) {
        top: -50px;
      }

      @media only screen and (min-width: 1600px) {
        width: 850px;
        top: -85px;
        padding: 60px 0;
      }

      h1 {
        text-align: center;
        letter-spacing: 1.65333px;

        @media only screen and (min-width: 992px) {
          letter-spacing: 6px;
        }
      }
    }

    .list {
      list-style-type: none;
      list-style-image: url("../../images/triangleBlueMobile.svg");
      padding-left: 20px;
      padding-right: 15px;
      margin-top: 70px;
      margin-bottom: 30px;

      @media only screen and (min-width: 992px) {
        list-style-image: url("../../images/triangleBlueMiddle.svg");
      }
      @media only screen and (min-width: 1600px) {
        list-style-image: url("../../images/triangleBlue.svg");
      }

      @media only screen and (min-width: 992px) {
        margin-top: 90px;
        padding-left: 50px;
        margin-bottom: 60px;
      }

      @media only screen and (min-width: 1200px) {
        margin-top: 110px;
        padding-left: 170px;
        padding-right: 0px;
        margin-bottom: 86px;
      }

      @media only screen and (min-width: 1400px) {
        padding-left: 200px;
        padding-right: 50px;
      }

      @media only screen and (min-width: 1800px) {
        padding-left: 200px;
        padding-right: 80px;
      }

      li {
        margin-bottom: 14px;

        h1 {
          color: #ff0000;
          margin-bottom: 14px;
          margin-left: 10px;

          @media only screen and (min-width: 992px) {
            margin-bottom: 16px;
            margin-left: 22px;
          }

          @media only screen and (min-width: 1800px) {
            margin-bottom: 14px;
            margin-left: 28px;
          }
        }

        @media only screen and (min-width: 1200px) {
          margin-bottom: 24px;
        }

        @media only screen and (min-width: 1600px) {
          margin-bottom: 32px;
        }

        p {
          margin-left: 10px;
        }

        @media only screen and (min-width: 992px) {
          p,
          h1 {
            margin-left: 22px;
          }
        }

        @media only screen and (min-width: 1600px) {
          p,
          h1 {
            margin-left: 28px;
          }
        }
      }
    }
  }
}
