@import "../../style/global.scss";

.footer {
  background-color: #171220;

  .footerContent {
    @media only screen and (min-width: 1100px) {
      display: flex;
      padding-top: 85px;
      padding-bottom: 45px;
    }

    .aboutFooter {
      padding-top: 40px;

      @media only screen and (min-width: 1100px) {
        padding-top: 0;
        width: 50%;

        p {
          width: 340px;
        }
      }

      img {
        margin-left: -10px;
        margin-bottom: 10px;

        @media only screen and (min-width: 1100px) {
          margin-top: -10px;
        }
      }
    }

    .menu {
      margin: 40px 0 50px;

      @media only screen and (min-width: 1100px) {
        margin: 0;
        width: 25%;
      }

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 24px;

        li {
          a {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.625px;
            color: #c6c6c6;
            text-decoration: none;

            &:hover {
              text-shadow: 0px 0px 4px #ffffff;
            }
          }
        }
      }
    }

    .social {
      h5 {
        text-transform: inherit;
        color: #c6c6c6;
        margin-bottom: 25px;
      }

      .icons {
        display: flex;
        align-items: center;
        gap: 40px;
        padding-bottom: 45px;
        border-bottom: 1px solid #272727;
        border-radius: 5px;

        @media only screen and (min-width: 1100px) {
          border-bottom: 0;
        }

        .facebook,
        .instagram {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border: 1px solid #272727;
          border-radius: 50%;

          &:hover {
            box-shadow: 0px 0px 4px #ffffff;
          }
        }
      }

      @media only screen and (min-width: 1100px) {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  .line {
    border-top: 1px solid #272727;
    margin-left: 20px;
    margin-right: 20px;

    @media only screen and (min-width: 768px) {
      margin-left: 120px;
      margin-right: 120px;
    }
  }

  .copyright {
    padding-top: 32px;
    padding-bottom: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;

    @media only screen and (min-width: 1100px) {
      padding-top: 25px;
      padding-bottom: 25px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .poweredRights,
    .termsPolicy {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media only screen and (min-width: 1100px) {
        flex-direction: row;
        gap: 55px;
      }

      p {
        color: #616161;
      }

      a {
        color: #767676;
        text-decoration: none;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.25px;

        &:hover {
          text-shadow: 0px 0px 4px #ffffff;
        }
      }
    }
  }
}
