.congratulations {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  padding: 20px 0;
  // padding: 20px 24px;
  position: fixed;

  @media only screen and (min-width: 600px) {
    width: 100%;
  }

  .congratulationsWrap {
    @media only screen and (min-width: 1200px) {
      width: 35%;
      margin: auto;
    }
  }

  .content {
    text-align: center;

    h2 {
      margin: 70px 0 16px;
    }

    p {
      margin-bottom: 32px;
      color: #fff;
      padding: 0 20px;

      @media only screen and (min-width: 600px) {
        width: 70%;
        margin: auto;
        margin-bottom: 32px;
      }
    }

    .roomResetAfter {
      margin-top: 32px;
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #cf004b;
        margin-bottom: 4px;
        text-transform: uppercase;
      }

      h4 {
        color: #fff;
        text-shadow: 0px 0px 17px #ffffff;
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
      }
    }

    .myClass {
      opacity: 0.5;
      cursor: none;
    }
  }
}
