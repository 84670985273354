@import "../style/global.scss";
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.btn {
  .button {
    font-family: "Exo 2", sans-serif;
    position: relative;
    background: transparent;
    padding: 20px 30px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    border: none;
    width: fit-content;
    cursor: pointer;
    margin: auto;

    &:hover {
      text-shadow: none;
    }

    &::before {
      position: absolute;
      content: "";
      height: 50px;
      width: 100%;
      left: 0;
      background: transparent;
      border: 1px solid #ffffff;
      box-shadow: 0px 0px 0px #ffffff;
      transform: skewX(145deg);
      // z-index: -1;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 5px;
    }

    &:hover::before {
      box-shadow: 0px 0px 17.2727px #ffffff;
    }

    &:focus::before {
      background: rgba(83, 83, 83, 0.3);
    }
  }
}
