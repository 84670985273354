.footerWrapper {
  background-image: url("../../images/footerBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 834px;

  @media only screen and (max-width: 768px) {
    background-image: url("../../images/footerMobile.png");
    height: 211px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      width: 15%;
      margin-bottom: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    background-image: url("../../images/footerMobile.png");
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      width: 15%;
      margin-bottom: 20px;
    }
  }
}
