.mobileHeader {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;

  .mobileHeaderContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 27px;
    .headerPopup {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: #0f0c16;
      box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
      transform: translate(0) !important;
      width: 100%;
      height: 100dvh;
      // height: fit-content;
      z-index: 4;

      .headerMain {
        display: flex;
        align-items: flex-end;
        justify-content: end;
        padding: 18px 27px;
      }

      .headerContent {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: -86px;
        height: 100%;

        .logoHeader {
          margin-bottom: 50px;
        }

        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 30px;
          list-style-type: none;

          li {
            a {
              font-size: 18px;
              font-weight: 700;
              line-height: 27px;
              letter-spacing: 2px;
              text-align: center;
              color: #fff;
              text-decoration: none;
              text-transform: uppercase;
            }
          }
        }

        .loginHeaderButton {
          margin-top: 40px;
          .button {
            font-family: "Exo 2", sans-serif;
            position: relative;
            background: transparent;
            padding: 20px 30px;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: 0px;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            display: flex;
            align-items: center;
            font-weight: 700;
            letter-spacing: 2px;
            font-size: 16px;
            line-height: 21px;
            border: none;
            width: 150px;
            cursor: pointer;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              text-shadow: none;
            }

            &::before {
              position: absolute;
              content: "";
              height: 50px;
              width: 100%;
              left: 0;
              background: transparent;
              border: 1px solid #ffffff;
              box-shadow: 0px 0px 0px #ffffff;
              transform: skewX(145deg);
              // z-index: -1;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 10px;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 5px;
            }

            &:hover::before {
              box-shadow: 0px 0px 17.2727px #ffffff;
            }

            &:focus::before {
              background: rgba(83, 83, 83, 0.3);
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1250px) {
      display: none;
    }
  }
}

.desktopHeader {
  position: absolute;
  width: 100%;
  top: 30px;

  .headerContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;

    .menuLeft {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: 40px;

      .menu {
        display: flex;
        align-items: center;
        list-style-type: none;
        gap: 40px;

        li {
          a {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: 2px;
            text-align: left;
            text-transform: uppercase;
            text-decoration: none;
            color: #fff;

            &:hover {
              color: white;
              text-shadow: 0 0 15px #fff;
            }
          }
        }
      }
    }

    .loginHeaderButton {
      .button {
        font-family: "Exo 2", sans-serif;
        position: relative;
        background: transparent;
        padding: 20px 30px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        display: flex;
        align-items: center;
        font-weight: 700;
        letter-spacing: 2px;
        font-size: 16px;
        line-height: 21px;
        border: none;
        width: 150px;
        cursor: pointer;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          text-shadow: none;
        }

        &::before {
          position: absolute;
          content: "";
          height: 50px;
          width: 100%;
          left: 0;
          background: transparent;
          border: 1px solid #ffffff;
          box-shadow: 0px 0px 0px #ffffff;
          transform: skewX(145deg);
          // z-index: -1;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 5px;
        }

        &:hover::before {
          box-shadow: 0px 0px 17.2727px #ffffff;
        }

        &:focus::before {
          background: rgba(83, 83, 83, 0.3);
        }
      }
    }
  }

  @media only screen and (max-width: 1250px) {
    display: none;
  }
}
