.contactFormWrapper {
  padding: 0 431px 0 250px;

  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    text-align: left;
    color: #000;
    margin-bottom: 24px;
    font-family: "TipoTypeHomeBold";

    @media only screen and (max-width: 1200px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 10px;
    }
  }

  .contactForm {
    width: 100%;
    margin-bottom: 70px;
    position: inherit;
    z-index: 11;

    @media only screen and (max-width: 1200px) {
      margin-bottom: 60px;
    }

    .inputsWrapper {
      display: flex;
      flex-direction: row;
      gap: 20px;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 0;
      }
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      gap: 7px;
      text-align: left;
      margin-bottom: 24px;
      width: 100%;

      @media only screen and (max-width: 1200px) {
        margin-bottom: 10px;
      }

      label {
        margin-left: 10px;
        font-weight: 700;
        color: #000000;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        font-family: "TipoTypeHomeBold";

        @media only screen and (max-width: 1200px) {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 1px;
          text-align: left;
        }
      }

      input {
        border: 2px solid #000000;
        height: 50px;
        outline: none;
        padding-left: 10px;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color: #000000;
        border-radius: 0 !important;
        font-family: "TipoTypeHomeRegular";

        @media only screen and (max-width: 1200px) {
          height: 41px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }

        &::placeholder {
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          text-align: left;
          color: #7d7d7d;

          @media only screen and (max-width: 1200px) {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      .errorMessage {
        text-align: left;
        color: #cf004b;
        font-size: 12px;
        margin: 0;
        margin-top: -5px;
        margin-bottom: -5px;
        margin-left: 10px;
        font-family: "TipoTypeHomeRegular";
      }

      textarea {
        border: 2px solid #000000;
        outline: none;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color: #000000;
        padding-top: 10px;
        resize: none;
        border-radius: 0 !important;
        font-family: "TipoTypeHomeRegular";

        @media only screen and (max-width: 1200px) {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }

        &::placeholder {
          color: #7d7d7d;
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          text-align: left;

          @media only screen and (max-width: 1200px) {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
          }
        }
      }
    }

    .submitButton {
      position: relative;
      background: transparent;
      padding: 20px 30px;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0px;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      border: none;
      cursor: pointer;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 100%;
      filter: drop-shadow(0 0 10px #ff0000);
      -webkit-filter: drop-shadow(0 0 5px #ff0000);
      box-shadow: none;
      text-shadow: none;
      padding-top: 25px;

      @media only screen and (max-width: 768px) {
        width: 90%;
      }

      @media only screen and (min-width: 768px) and (max-width: 1200px) {
        width: 300px;
      }

      span {
        color: #fff;
        z-index: 9;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        font-family: "TipoTypeHomeBold";

        @media only screen and (max-width: 1200px) {
          font-size: 14px;
          font-weight: 700;
          line-height: 21.27px;
          letter-spacing: 2px;
          text-align: center;
        }
      }

      &:hover {
        text-shadow: none;
      }

      &::before {
        position: absolute;
        content: "";
        height: 50px;
        width: 100%;
        left: 0;
        background: #ff0000;
        color: #fff;
        box-shadow: 0px 0px 0px #ffffff;
        transform: skewX(145deg);
        // z-index: -1;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 5px;
        transition: box-shadow 0.3s ease;

        @media only screen and (max-width: 1200px) {
          height: 41px;
        }
      }

      &:hover::before {
        box-shadow: 0px 0px 17.2727px #ffffff;
      }

      // &:focus::before {
      //   background: rgba(83, 83, 83, 0.3);
      //   height: 100%;
      // }
    }

    .loaderContact {
      align-items: center;
      justify-content: center;
    }
  }
}
