.headerWrapper {
  position: absolute;
  top: 39px;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    top: 20px;
  }

  .headerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;

    @media only screen and (max-width: 1200px) {
      padding: 0 20px;
    }

    img {
      @media only screen and (max-width: 1200px) {
        width: 132px;
      }
    }

    .loginHeaderButton {
      .button {
        position: relative;
        background: transparent;
        padding: 20px 30px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        display: flex;
        align-items: center;
        font-weight: 700;
        letter-spacing: 2px;
        font-size: 16px;
        line-height: 21px;
        border: none;
        width: 150px;
        cursor: pointer;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          text-shadow: none;
        }

        &::before {
          position: absolute;
          content: "";
          height: 50px;
          width: 100%;
          left: 0;
          background: transparent;
          border: 2px solid #ffffff;
          // box-shadow: 0px 0px 0px #ffffff;
          transform: skewX(145deg);
          // z-index: -1;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 5px;
          box-shadow: 0px 0px 20px #ffffff;
        }

        &:hover::before {
          box-shadow: 0px 0px 25.2727px #ffffff;
        }

        &:focus::before {
          background: rgba(83, 83, 83, 0.3);
        }
      }
    }
  }
}
