.banerWrapper {
  background-image: url("../../images/homeBaner.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 590px;

  display: flex;
  align-items: end;

  @media only screen and (max-width: 768px) {
    background-image: url("../../images/homeBanerMobile.png");
    height: auto;
  }

  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    background-image: url("../../images/tabletBaner.png");
    height: auto;
  }

  .banerContent {
    padding: 0 0 70px 60px;

    @media only screen and (max-width: 1200px) {
      padding: 106px 20px 58px;
    }

    .desktop {
      font-size: 45px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: 6px;
      text-transform: uppercase;
      text-align: left;
      color: #fff;
      text-shadow: #ffffff66 0px 0 10px;
      font-family: "TipoTypeHomeRegular";
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        .desktop {
          text-shadow: #ffffff66 0px 0 5px; /* Remove text shadow for Safari */
        }
      }
    }

    .mobile {
      font-size: 32px;
      font-weight: 700;
      line-height: 46px;
      letter-spacing: 6px;
      text-align: left;
      color: #fff;
      text-transform: uppercase;
      text-shadow: #ffffff66 0px 0 10px;
      font-family: "TipoTypeHomeRegular";
    }

    .chipWrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin: 10px 0 20px;

      @media only screen and (max-width: 1200px) {
        flex-wrap: wrap;
        margin: 16px 0 20px;
        gap: 4px;
      }

      .chip {
        background: rgba(255, 0, 0, 0.15);
        border-radius: 5px;

        // @media only screen and (max-width: 1200px) {
        //   width: 190px !important;
        //   height: 27px;
        // }

        h3 {
          font-size: 16px;
          font-weight: 700;
          line-height: 24.3px;
          letter-spacing: 2px;
          text-align: center;
          color: #ff0000;
          padding: 6px 10px;
          // text-shadow: #ff0000 0px 0 10px;
          font-family: "TipoTypeHomeBold";

          @media only screen and (max-width: 1200px) {
            font-size: 10px;
            font-weight: 700;
            line-height: 15.19px;
            letter-spacing: 2px;
            text-align: center;
            padding: 6px;
          }
        }
      }

      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          .chip {
            h3 {
              text-shadow: #ff000079 0px 0 3px; /* Remove text shadow for Safari */
            }
          }
        }
      }
    }

    .desktopText {
      font-size: 25px;
      font-weight: 400;
      line-height: 34px;
      text-align: left;
      text-shadow: #ffffffcc 1px 0 10px;
      color: #ffffff;
      font-family: "TipoTypeHomeRegular";
    }

    .mobileText {
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      text-shadow: #ffffffcc 1px 0 10px;
      color: #ffffff;
      font-family: "TipoTypeHomeRegular";
    }

    .loginHeaderButton {
      .button {
        position: relative;
        background: transparent;
        padding: 10px 50px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        display: flex;
        align-items: center;
        font-weight: 700;
        letter-spacing: 2px;
        font-size: 14px;
        line-height: 21px;
        border: none;
        width: fit-content;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-left: 10px;

        &:hover {
          text-shadow: none;
        }

        &::before {
          position: absolute;
          content: "";
          height: 41px;
          width: 100%;
          left: 0;
          background: transparent;
          border: 1px solid #ffffff;
          box-shadow: 0px 0px 0px #ffffff;
          transform: skewX(145deg);
          // z-index: -1;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 5px;
        }

        &:hover::before {
          box-shadow: 0px 0px 17.2727px #ffffff;
        }

        &:focus::before {
          background: rgba(83, 83, 83, 0.3);
        }
      }
    }
  }
}
