@font-face {
  font-family: "Rexton-Medium";
  src: local("Rexton-Medium"),
    url("../../src/font/Rexton-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Rexton-Light";
  src: local("Rexton-Light"),
    url("../../src/font/Rexton-Light.otf") format("truetype");
}

@font-face {
  font-family: "Normatica-Bold";
  src: local("Normatica-Bold"),
    url("../../src/font/Normatica-Bold.ttf") format("truetype");
}

.receptionWrap {
  background-image: url("../images/receptionBgOpt.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  @media only screen and (min-width: 3500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .receptionContent {
    padding: 75px 30px 0px;

    @media only screen and (max-width: 1500px) {
      padding-top: 40px;
    }

    @media only screen and (min-width: 2000px) {
      padding-top: 100px;
    }

    @media only screen and (min-width: 3500px) {
      padding-top: 0;
    }

    .topContent {
      display: flex;
      flex-direction: row;
      align-items: end;
      justify-content: space-between;

      .topCopmanu {
        display: flex;
        flex-direction: column;
        width: 35%;

        @media only screen and (min-width: 2000px) {
          gap: 10px;
        }

        h4 {
          font-size: 24px;
          text-transform: uppercase;
          color: #757895;
          text-align: center;
          font-family: "Rexton-Light";
          text-shadow: 4px 4px 8px #000;

          @media only screen and (max-width: 1500px) {
            font-size: 18px;
          }

          @media only screen and (min-width: 2000px) {
            font-size: 35px;
          }

          @media only screen and (min-width: 3500px) {
            font-size: 55px;
          }
        }

        .topCompanyTitle {
          font-size: 28px;
          line-height: 45px;
          text-transform: uppercase;
          text-align: center;
          color: #fff;
          font-family: "Rexton-Medium";
          text-shadow: 4px 4px 8px #000;

          @media only screen and (max-width: 1500px) {
            font-size: 20px;
            line-height: 30px;
            // line-height: inherit;
          }

          @media only screen and (min-width: 2000px) {
            font-size: 35px;
          }

          @media only screen and (min-width: 3500px) {
            font-size: 55px;
            line-height: 100px;
          }
        }
      }

      .receptionLogo {
        width: 35%;
        margin: auto;
        text-align: center;
        margin-bottom: 0px;

        @media only screen and (max-width: 1500px) {
          margin-bottom: -3px;
        }

        // @media only screen and (min-width: 2000px) {
        //   margin-bottom: -20px;
        // }

        // @media only screen and (min-width: 3500px) {
        //   margin-bottom: -12px;
        // }

        @media only screen and (min-width: 3500px) {
          margin-bottom: 10px;
        }

        img {
          width: 45%;

          @media only screen and (max-width: 1500px) {
            width: 50%;
          }

          @media only screen and (min-width: 2000px) {
            width: 45%;
          }
        }
      }
    }

    .eliteTop {
      margin-top: 90px;

      @media only screen and (max-width: 1500px) {
        margin-top: 50px;
      }

      @media only screen and (min-width: 2000px) {
        margin-top: 150px;
      }

      // @media only screen and (min-width: 3500px) {
      //   margin-top: 150px;
      // }

      h1 {
        font-size: 90px;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 8px 8px 16px #000;
        font-family: "Rexton-Medium";
        font-weight: 500;
        letter-spacing: -0.09em;
        text-align: center;

        @media only screen and (max-width: 1500px) {
          font-size: 50px;
        }

        @media only screen and (min-width: 2000px) {
          font-size: 120px;
        }

        @media only screen and (min-width: 3500px) {
          font-size: 150px;
        }
      }
    }

    .receptionCarousel {
      margin-top: 65px;

      @media only screen and (max-width: 1500px) {
        margin-top: 40px;
      }

      @media only screen and (min-width: 2000px) {
        margin-top: 120px;
      }

      .owl-dots {
        counter-reset: slides-num; /* Initialize counter. */
        position: absolute;
        left: 50%;
        bottom: -10%;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 2px;

        @media only screen and (max-width: 1500px) {
          align-items: baseline;
        }

        @media only screen and (min-width: 2000px) {
          bottom: -20%;
          gap: 5px;
        }

        @media only screen and (min-width: 3500px) {
          bottom: -12%;
        }

        &:after {
          content: counter(
            slides-num
          ); /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
          display: inline-block;
          font-family: "Normatica-Bold";
          font-size: 24px;
          vertical-align: middle;

          @media only screen and (max-width: 1500px) {
            font-size: 14px;
          }

          @media only screen and (min-width: 2000px) {
            font-size: 35px;
          }

          @media only screen and (min-width: 3500px) {
            font-size: 55px;
          }
        }
      }

      .owl-dot {
        display: inline-block;
        counter-increment: slides-num; /* Increment counter */

        span {
          display: none;
        }

        &.active {
          &:before {
            content: counter(slides-num) " /"; /* Use the same counter to get current item. */
            display: inline-block;
            vertical-align: middle;
            font-family: "Normatica-Bold";
            font-size: 24px;

            @media only screen and (max-width: 1500px) {
              font-size: 14px;
            }

            @media only screen and (min-width: 2000px) {
              font-size: 35px;
            }

            @media only screen and (min-width: 3500px) {
              font-size: 55px;
            }
          }
        }
      }

      .owl-carousel {
        .owl-stage-outer {
          .owl-stage {
            .owl-item {
              &:nth-child(2n + 2) {
                .item {
                  .winner {
                    .dot,
                    .dot2 {
                      animation: xAxis 20s linear infinite;
                    }

                    .dot1,
                    .dot3 {
                      animation: YAxis 20s linear infinite;
                    }
                  }
                }
              }

              &:nth-child(3n + 3) {
                .item {
                  .winner {
                    .dot,
                    .dot2 {
                      animation: xAxis 15s linear infinite;
                    }

                    .dot1,
                    .dot3 {
                      animation: YAxis 15s linear infinite;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .winner {
        width: 97%;
        border: 2px solid #ff912c;
        position: relative;
        background-color: #000;
        margin: 15px 0;

        .dot {
          position: absolute;
          right: -8px;
          bottom: -7px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #ff912c;
          box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 145, 44, 0.7);
          animation: xAxis 10s linear infinite;

          @media only screen and (max-width: 960px) {
            bottom: -8px;
          }

          @keyframes xAxis {
            0% {
              left: 0;
            }
            50% {
              left: 100%;
            }
            100% {
              left: 0;
            }
          }
        }

        .dot1 {
          position: absolute;
          // left: -7px;
          left: -9px;
          bottom: -5px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #ff912c;
          box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 145, 44, 0.7);
          animation: YAxis 10s linear infinite;

          @media only screen and (max-width: 960px) {
            left: -8px;
          }

          @keyframes YAxis {
            0% {
              top: 0;
            }
            50% {
              top: 100%;
            }
            100% {
              top: 0;
            }
          }
        }

        .dot2 {
          position: absolute;
          right: -8px;
          top: -7px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #ff912c;
          box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 145, 44, 0.7);
          animation: xAxis 10s linear infinite;

          @media only screen and (max-width: 960px) {
            top: -8px;
          }

          @keyframes xAxis {
            0% {
              left: 0;
            }
            50% {
              left: 100%;
            }
            100% {
              left: 0;
            }
          }
        }

        .dot3 {
          position: absolute;
          // right: -7px;
          right: -8px;
          bottom: -5px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #ff912c;
          box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 145, 44, 0.7);
          animation: YAxis 10s linear infinite;

          @media only screen and (max-width: 960px) {
            right: -8px;
          }

          @keyframes YAxis {
            0% {
              top: 0;
            }
            50% {
              top: 100%;
            }
            100% {
              top: 0;
            }
          }
        }

        .winnerLogo {
          font-family: "Normatica-Bold";
          text-align: center;
          color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 45px 0 35px;
          border-bottom: 2px solid #ff912c;

          @media only screen and (max-width: 1500px) {
            padding: 20px 0 15px;
          }

          @media only screen and (min-width: 2000px) {
            padding: 55px 0 50px;
          }

          @media only screen and (min-width: 3500px) {
            padding: 90px 0 75px;
          }

          h4 {
            font-size: 48px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #ff912c;

            @media only screen and (max-width: 1500px) {
              font-size: 30px;
            }

            @media only screen and (min-width: 3500px) {
              font-size: 80px;
            }
          }

          .placement {
            display: flex;
            flex-direction: row;
            align-items: baseline;

            h5 {
              display: flex;
              align-items: self-start;
              font-size: 28px;
              text-transform: uppercase;

              @media only screen and (max-width: 960px) {
                font-size: 20px;
              }

              span {
                font-size: 11px !important;
                margin-top: -7px;
              }
            }
          }
        }

        .winnerInfo {
          min-height: 285px;

          @media only screen and (min-width: 1500px) {
            min-height: 395px;
          }

          @media only screen and (min-width: 2000px) {
            // min-height: 470px;
            min-height: fit-content;
          }

          @media only screen and (min-width: 3800px) {
            // min-height: 920px;
            min-height: fit-content;
          }

          .winnerInfoContent {
            padding: 20px 10px;

            @media only screen and (max-width: 1500px) {
              padding: 10px 5px;
            }

            @media only screen and (min-width: 2000px) {
              padding: 40px 20px;
            }

            // @media only screen and (min-width: 3500px) {
            //   font-size: 150px;
            // }

            .table {
              color: #fff;
              display: flex;
              flex-direction: column;
              gap: 20px;

              .tableInfo {
                display: flex;
                align-items: center;
                border: 1px solid #6e6f71;
                font-family: "Normatica-Bold";

                .place {
                  width: 53px;
                  height: 53px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-right: 1px solid #6e6f71;
                  font-size: 16px;
                  font-weight: 400;

                  @media only screen and (max-width: 1500px) {
                    width: 35px;
                    height: 35px;
                    font-size: 14px;
                  }

                  @media only screen and (min-width: 2000px) {
                    width: 60px;
                    height: 60px;
                    font-size: 18px;
                  }

                  @media only screen and (min-width: 3500px) {
                    width: 150px;
                    height: 150px;
                    font-size: 35px;
                  }
                }

                .teamName {
                  width: 30%;
                  height: 53px;
                  display: flex;
                  align-items: center;
                  border-right: 1px solid #6e6f71;
                  padding-left: 20px;
                  font-size: 16px;
                  text-transform: uppercase;

                  @media only screen and (max-width: 1500px) {
                    height: 30px;
                    font-size: 12px;
                    padding-left: 10px;
                  }
                  @media only screen and (min-width: 2000px) {
                    font-size: 18px;
                  }

                  @media only screen and (min-width: 3500px) {
                    height: 150px;
                    font-size: 35px;
                  }
                }

                .score {
                  text-align: left;
                  display: flex;
                  align-items: center;
                  // margin: auto;
                  padding-left: 20px;
                  font-size: 16px;

                  @media only screen and (max-width: 1500px) {
                    padding-left: 10px;
                    font-size: 10px;
                  }
                  @media only screen and (min-width: 2000px) {
                    font-size: 18px;
                  }
                  @media only screen and (min-width: 3500px) {
                    font-size: 32px;
                    padding-left: 30px;
                  }
                }
              }
            }
          }
        }
      }

      .fixedRoomsView {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .winner {
          width: 35%;
          &:nth-child(1) {
            .dot,
            .dot2 {
              animation: xAxis 15s linear infinite;
            }

            .dot1,
            .dot3 {
              animation: YAxis 15s linear infinite;
            }
          }
          &:nth-child(2) {
            .dot,
            .dot2 {
              animation: xAxis 20s linear infinite;
            }

            .dot1,
            .dot3 {
              animation: YAxis 20s linear infinite;
            }
          }
          &:nth-child(3) {
            .dot,
            .dot2 {
              animation: xAxis 12s linear infinite;
            }

            .dot1,
            .dot3 {
              animation: YAxis 12s linear infinite;
            }
          }
        }
      }

      .custom-dots {
        font-family: "Normatica-Bold";
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;

        @media only screen and (max-width: 1500px) {
          font-size: 14px;
        }
      }
    }
  }
}
