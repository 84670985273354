.tokenError {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;

  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;

  h2 {
    padding: 0px 24px;
    margin-bottom: 16px;
  }

  p {
    color: #fff;
  }
}
