.join {
  .image {
    width: 100%;
    margin-bottom: -13px;

    @media only screen and (min-width: 1200px) {
      position: relative;
      z-index: 0;
      margin-bottom: -32px;
    }

    @media only screen and (min-width: 1600px) {
      margin-bottom: -40px;
    }
  }

  .redLine {
    width: 90%;
    height: 10px;
    background-color: #ff0000;
    margin-top: 8px;
    margin-bottom: -10px;
    float: right;

    @media only screen and (min-width: 1300px) {
      float: unset;
      margin-top: 29px;
    }

    @media only screen and (min-width: 1700px) {
      margin-top: 37px;
    }

    @media only screen and (min-width: 1400px) {
      height: 15px;
      width: 75%;
      margin-left: auto;

      margin-bottom: -15px;
    }
  }

  .container {
    margin: 0 27px;
    position: relative;
    padding: 0;
    z-index: 9;
    padding-top: 9px;

    @media only screen and (min-width: 1200px) {
      margin: 0 120px 0 120px;
      padding-top: unset !important;
    }

    @media only screen and (min-width: 1800px) {
      margin: 0 150px 0 150px;
    }

    .joinLine {
      position: absolute;
      right: 10%;
      z-index: 0;

      @media only screen and (max-width: 1200px) {
        display: none;
      }

      @media only screen and (min-width: 1800px) {
        height: 1400px;
        display: block;
      }
    }

    .title {
      background: #ffffff;
      border: 1px solid #000000;
      padding: 20px 13px;
      width: 187px;
      position: absolute;
      // top: -35px;
      right: 26px;
      margin-bottom: 25px;
      left: 30px;

      @media only screen and (min-width: 992px) {
        border: 3px solid #000000;
        border-top: unset;
        width: 650px;
        padding: 50px 0;
        left: unset;
      }

      @media only screen and (min-width: 1200px) {
        padding: 50px 0;
        z-index: 1;
      }

      @media only screen and (min-width: 1600px) {
        width: 850px;
        padding: 60px 0;
      }

      h1 {
        text-align: center;
        letter-spacing: 1.65333px;

        @media only screen and (min-width: 992px) {
          letter-spacing: 6px;
        }
      }
    }

    .list {
      list-style-type: none;
      list-style-image: url("../../images/triangleRedMobile.svg");
      padding-left: 20px;
      padding-right: 15px;
      margin-bottom: 30px;
      padding-top: 85px;

      @media only screen and (min-width: 992px) {
        list-style-image: url("../../images/triangleRedMiddle.svg");
        margin-bottom: 30px;
        padding-top: 100px;
      }
      @media only screen and (min-width: 1600px) {
        list-style-image: url("../../images/triangleRed.svg");
      }

      @media only screen and (min-width: 992px) {
        padding-left: 50px;
        margin-bottom: 60px;
        padding-top: 180px;
      }

      @media only screen and (min-width: 1200px) {
        padding-left: 0px;
        padding-right: 220px;
        margin-bottom: 86px;
        padding-top: 215px;
      }

      @media only screen and (min-width: 1400px) {
        padding-left: 110px;
        padding-right: 320px;
      }

      @media only screen and (min-width: 1600px) {
        padding-top: 250px;
      }

      @media only screen and (min-width: 1800px) {
        padding-left: 150px;
        padding-right: 400px;
      }

      h1 {
        color: #0089cf;
        margin-bottom: 6px;
        margin-left: 10px;

        @media only screen and (min-width: 992px) {
          margin-bottom: 16px;
          margin-left: 22px;
        }

        @media only screen and (min-width: 1800px) {
          margin-bottom: 14px;
          margin-left: 28px;
        }
      }

      p {
        margin-bottom: 20px;
        margin-left: 10px;

        @media only screen and (min-width: 992px) {
          margin-bottom: 30px;
          margin-left: 22px;
        }
        @media only screen and (min-width: 1800px) {
          margin-left: 28px;
        }
      }

      li {
        margin-bottom: 14px;

        p {
          margin-left: 10px;
        }

        @media only screen and (min-width: 992px) {
          p,
          h1 {
            margin-left: 22px;
          }
        }

        @media only screen and (min-width: 1600px) {
          p,
          h1 {
            margin-left: 28px;
          }
        }

        @media only screen and (min-width: 1200px) {
          margin-bottom: 24px;
        }

        @media only screen and (min-width: 1600px) {
          margin-bottom: 32px;
        }
      }
    }
  }
}
