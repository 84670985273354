.new {
  background: #232323;
  height: 100vh;
  padding: 12px 0px 40px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .bar {
    background: #d9d9d9;
    padding: 17px 0;

    ul {
      list-style-type: none;
      display: flex;
      flex-direction: row;

      li {
        &:nth-child(1) {
          width: 21%;
          text-align: right;
        }

        &:nth-child(2) {
          width: 16%;
          text-align: right;
        }
        &:nth-child(3) {
          width: 13%;
          text-align: right;
        }

        &:nth-child(4) {
          width: 12%;
          text-align: right;
        }
        &:nth-child(5) {
          width: 19%;
          text-align: right;
        }
        &:nth-child(6) {
          width: 11%;
          text-align: right;
        }
      }
    }
  }

  .teamCard {
    background-color: #fff;
    margin: 52px 20px 0;
    border-radius: 60px;
    display: flex;
    flex-direction: row;
    gap: 12px;

    &:nth-child(3) {
      .timNum {
        background-color: #462cff;
      }

      .teamInfo .info .timeLeft {
        background-color: #462cff;
      }

      .teamInfo .info .progressNum {
        background-color: #462cff;
      }

      .teamInfo .progresses {
        progress {
          width: 100%;
          height: 10px;
          background: #462cff;
          &::-webkit-progress-value {
            background: #462cff;
            box-shadow: 0px 0px 9px 4px #462cff;
            transition: all 2s cubic-bezier(0.47, 1.64, 0.41, 0.8);
          }
        }
      }
    }

    &:nth-child(4) {
      .timNum {
        background-color: #ff912c;
      }

      .teamInfo .info .timeLeft {
        background-color: #ff912c;
      }

      .teamInfo .info .progressNum {
        background-color: #ff912c;
      }

      .teamInfo .progresses {
        progress {
          width: 100%;
          height: 10px;
          background: #ff912c;
          &::-webkit-progress-value {
            background: #ff912c;
            box-shadow: 0px 0px 9px 4px #ff912c;
            transition: all 2s cubic-bezier(0.47, 1.64, 0.41, 0.8);
          }
        }
      }
    }

    &:nth-child(5) {
      .timNum {
        background-color: #74cf00;
      }

      .teamInfo .info .timeLeft {
        background-color: #74cf00;
      }

      .teamInfo .info .progressNum {
        background-color: #74cf00;
      }

      .teamInfo .progresses {
        progress {
          width: 100%;
          height: 10px;
          background: #74cf00;
          &::-webkit-progress-value {
            background: #74cf00;
            box-shadow: 0px 0px 9px 4px #74cf00;
            transition: all 2s cubic-bezier(0.47, 1.64, 0.41, 0.8);
          }
        }
      }
    }

    &:nth-child(6) {
      .timNum {
        background: #cf00ae;
      }

      .teamInfo .info .timeLeft {
        background-color: #cf00ae;
      }

      .teamInfo .info .progressNum {
        background-color: #cf00ae;
      }

      .teamInfo .progresses {
        progress {
          width: 100%;
          height: 10px;
          background: #cf00ae;
          &::-webkit-progress-value {
            background: #cf00ae;
            box-shadow: 0px 0px 9px 4px #cf00ae;
            transition: all 2s cubic-bezier(0.47, 1.64, 0.41, 0.8);
          }
        }
      }
    }

    &:nth-child(7) {
      .timNum {
        background-color: #9859ff;
      }

      .teamInfo .info .timeLeft {
        background-color: #9859ff;
      }

      .teamInfo .info .progressNum {
        background-color: #9859ff;
      }

      .teamInfo .progresses {
        progress {
          width: 100%;
          height: 10px;
          background: #9859ff;
          &::-webkit-progress-value {
            background: #9859ff;
            box-shadow: 0px 0px 9px 4px #9859ff;
            transition: all 2s cubic-bezier(0.47, 1.64, 0.41, 0.8);
          }
        }
      }
    }

    &:nth-child(8) {
      .timNum {
        background-color: #009dcf;
      }

      .teamInfo .info .timeLeft {
        background-color: #009dcf;
      }

      .teamInfo .info .progressNum {
        background-color: #009dcf;
      }

      .teamInfo .progresses {
        progress {
          width: 100%;
          height: 10px;
          background: #009dcf;
          &::-webkit-progress-value {
            background: #009dcf;
            box-shadow: 0px 0px 9px 4px #009dcf;
            transition: all 2s cubic-bezier(0.47, 1.64, 0.41, 0.8);
          }
        }
      }
    }

    &:nth-child(9) {
      .timNum {
        background-color: #00cf6c;
      }

      .teamInfo .info .timeLeft {
        background-color: #00cf6c;
      }

      .teamInfo .info .progressNum {
        background-color: #00cf6c;
      }

      .teamInfo .progresses {
        progress {
          width: 100%;
          height: 10px;
          background: #00cf6c;
          &::-webkit-progress-value {
            background: #00cf6c;
            box-shadow: 0px 0px 9px 4px #00cf6c;
            transition: all 2s cubic-bezier(0.47, 1.64, 0.41, 0.8);
          }
        }
      }
    }

    .timNum {
      background-color: #cf004b;
      border-top-left-radius: 60px;
      border-bottom-left-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 64px;
      line-height: 87px;
      color: #fff;
      padding: 40px 25px;
    }

    .teamInfo {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;

      .info {
        display: flex;
        align-items: center;
        width: 100%;

        .teamImg {
          width: 10%;

          img {
            width: 140px;
            height: 120px;
            object-fit: cover;
          }
        }

        .teamName {
          font-style: normal;
          font-weight: 300;
          font-size: 32px;
          line-height: 38px;
          color: #060817;
          width: 15%;

          p {
            font-family: "Exo 2";
            font-style: normal;
            font-weight: 300;
            font-size: 40px;
            line-height: 48px;
            color: #060817;
            width: 100px;
          }
        }

        .companyRoom {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 20%;
        }

        .city {
          width: 10%;
        }

        .hintsGiven {
          width: 20%;

          .allHints {
            display: flex;
            flex-direction: row;
            gap: 10px;
            position: relative;

            .shake {
              animation: blink linear 1s;
            }

            @keyframes blink {
              78% {
                opacity: 0.2;
              }
              79% {
                opacity: 0.3;
              }
              80% {
                opacity: 1;
              }
              81% {
                opacity: 0.3;
              }
              82% {
                opacity: 0.5;
              }
              83% {
                opacity: 0.2;
              }
              92% {
                opacity: 0.5;
              }
              94% {
                opacity: 0.2;
              }
              95% {
                opacity: 0.5;
              }
              100% {
                opacity: 1;
              }
            }

            .aditionalHints {
              position: absolute;
              top: -10px;
              right: 50px;
              color: #060817;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }

        .timeLeft {
          background-color: #cf004b;
          border-radius: 60px;
          font-style: normal;
          font-weight: 500;
          font-size: 48px;
          line-height: 58px;
          color: #fff;
          padding: 18px 29px;
          margin-right: 30px;
          text-align: center;
          width: 120px;
        }

        .progressNum {
          background-color: #cf004b;
          border-radius: 60px;
          font-style: normal;
          font-weight: 500;
          font-size: 48px;
          line-height: 58px;
          color: #fff;
          padding: 18px 29px;
        }
      }

      .progresses {
        width: 89%;
        margin-left: 90px;

        progress {
          width: 100%;
          height: 10px;
          background: #cf004b;

          &::-webkit-progress-value {
            background: #cf004b;
            box-shadow: 0px 0px 9px 4px #cf004b;

            // Bounce
            transition: all 2s cubic-bezier(0.47, 1.64, 0.41, 0.8);

            // Go back
            // transition: all 0.5s cubic-bezier(0.6, -0.28, 0.74, 0.05);
          }
        }
      }
    }
  }

  .topPlaces {
    h3 {
      text-align: center;
      margin: 40px 0;
    }

    .topPlacesCards {
      margin: 0 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 40px;

        &:nth-child(2) {
          .topPlace {
            &:nth-child(1) {
              .teamId {
                background: #cf004b;
              }
            }

            &:nth-child(2) {
              .teamId {
                background: #462cff;
              }
            }

            &:nth-child(3) {
              .teamId {
                background: #ff912c;
              }
            }

            &:nth-child(4) {
              .teamId {
                background: #74cf00;
              }
            }

            &:nth-child(5) {
              .teamId {
                background: #cf00ae;
              }
            }
          }
        }

        .topPlace {
          width: 900px;
          height: 85px;
          background-color: #fff;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:nth-child(1) {
            .teamId {
              background: #462cff;
            }
          }

          &:nth-child(2) {
            .teamId {
              background: #ff912c;
            }
          }

          &:nth-child(3) {
            .teamId {
              background: #74cf00;
            }
          }

          &:nth-child(4) {
            .teamId {
              background: #cf00ae;
            }
          }

          &:nth-child(5) {
            .teamId {
              background: #9859ff;
            }
          }

          .teamId {
            width: 10%;
            text-align: center;
            height: 100%;
            background: #462cff;
            display: flex;
            justify-content: center;
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
            align-items: center;
            font-style: normal;
            font-weight: 400;
            font-size: 48px;
            line-height: 58px;
            color: #fff;
          }

          .teamName {
            width: 80%;
            font-family: "Exo 2";
            font-style: normal;
            font-weight: 300;
            font-size: 40px;
            line-height: 48px;
            color: #060817;
            margin-left: 36px;
          }

          .teamPoints {
            width: 15%;
            font-style: normal;
            font-weight: 400;
            font-size: 40px;
            line-height: 48px;
            text-align: right;
            color: #000;
            margin-right: 40px;
          }
        }
      }
    }
  }
}
