@font-face {
  font-family: "TipoType"; /*Can be any text*/
  src: local("TipoType-Bold"),
    url("../../font/TipoType-Bold.otf") format("opentype");
}

@font-face {
  font-family: "TipoTypeRegular"; /*Can be any text*/
  src: local("TipoType-Regular"),
    url("../../font/TipoType-Regular.otf") format("opentype");
}

a {
  font-family: "TipoTypeRegular";
  font-weight: bold;
}

h1 {
  font-family: "TipoType";
  font-size: 14px;
  color: #000000;
  line-height: 19px;
  letter-spacing: 1.65333px;

  @media only screen and (min-width: 992px) {
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 6px;
  }

  @media only screen and (min-width: 1600px) {
    font-size: 38px;
    letter-spacing: 8px;
    line-height: 60px;
  }
}

p {
  font-family: "TipoTypeRegular";
  font-size: 14px;
  line-height: 18px;
  color: #000;

  @media only screen and (min-width: 992px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media only screen and (min-width: 1600px) {
    font-size: 26px;
    line-height: 37px;
  }
}
