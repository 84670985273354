.videoSection {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;

  .video {
    width: 100%;

    .frame {
      padding: 15px;

      .clip {
        width: 100%;
        height: 600px;
      }
    }
  }
}
