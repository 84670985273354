.listingPage {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  //   padding: 20px 24px;
  position: relative;

  .listingPageWrap {
    @media only screen and (min-width: 1200px) {
      width: 35%;
      margin: auto;
    }
  }

  .completeRoom {
    font-family: "Exo 2", sans-serif;
    position: relative;
    background: transparent;
    padding: 20px 30px;
    font-style: normal;
    letter-spacing: 0px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 100%;
    cursor: pointer;
    margin: auto;

    &:hover {
      text-shadow: none;
    }

    &::before {
      position: absolute;
      content: "";
      height: 50px;
      width: 100%;
      left: 0;
      background: transparent;
      border: 1px solid #00ff29;
      box-shadow: 0px 0px 5px #00ff29;
      transform: skewX(145deg);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 5px;
    }

    &:hover::before {
      box-shadow: 0px 0px 5px #00ff29;
    }

    &:focus::before {
      background: rgba(83, 83, 83, 0.3);
    }
  }

  .resetRoom {
    padding: 0 20px;
    .resetBtn {
      font-family: "Exo 2", sans-serif;
      position: relative;
      background: transparent;
      padding: 20px 30px;
      font-style: normal;
      letter-spacing: 0px;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      width: 100%;
      cursor: pointer;
      margin: auto;

      &:hover {
        text-shadow: none;
      }

      &::before {
        position: absolute;
        content: "";
        height: 50px;
        width: 100%;
        left: 0;
        background: transparent;
        border: 1px solid #cf004b;
        box-shadow: 0px 0px 5px #cf004b;
        transform: skewX(145deg);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 5px;
      }

      &:hover::before {
        box-shadow: 0px 0px 5px #cf004b;
      }

      &:focus::before {
        background: rgba(83, 83, 83, 0.3);
      }
    }
  }

  .header {
    background: rgba(0, 0, 0, 0.65);
    padding: 26px 40px;

    .completeRoom {
      font-family: "Exo 2", sans-serif;
      position: relative;
      background: transparent;
      padding: 20px 30px;
      font-style: normal;
      letter-spacing: 0px;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      width: 100%;
      cursor: pointer;
      margin: auto;

      &:hover {
        text-shadow: none;
      }

      &::before {
        position: absolute;
        content: "";
        height: 50px;
        width: 100%;
        left: 0;
        background: transparent;
        border: 1px solid #00ff29;
        box-shadow: 0px 0px 5px #00ff29;
        transform: skewX(145deg);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 5px;
      }

      &:hover::before {
        box-shadow: 0px 0px 5px #00ff29;
      }

      &:focus::before {
        background: rgba(83, 83, 83, 0.3);
      }
    }
  }

  .content {
    padding: 16px 16px 40px;

    .timeSpent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(85, 77, 102, 0.25);
      border-radius: 5px;
      padding: 20px 25px;
      margin-bottom: 16px;

      h6 {
        color: #fff;
      }

      span {
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.625px;
        color: #fff;
        text-shadow: 0px 0px 17px #ffffff;
        min-width: 75px;
      }
    }

    .hints {
      background: rgba(85, 77, 102, 0.25);
      border-radius: 5px;
      padding: 20px 25px;
      margin-bottom: 16px;
      text-align: center;

      h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.75px;
        color: #fff;
        margin-bottom: 20px;
      }

      .allHints {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 20px;
        margin-bottom: 40px;
        position: relative;

        span {
          position: absolute;
          color: #fff;
          bottom: -30px;
          right: 0;
          left: 0;
        }
      }
    }

    .btn {
      button {
        width: 100% !important;
        justify-content: center;
      }
    }

    .steps {
      background: rgba(85, 77, 102, 0.25);
      border-radius: 5px;
      padding: 20px 25px;
      margin-bottom: 20px;

      h6 {
        color: #fff;
        border-bottom: 1px solid #48386b;
        padding-bottom: 20px;
      }

      .allSteps {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
        margin-top: 20px;

        .roomStep {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row-reverse;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.75px;
          color: #fff;
          position: relative;

          input {
            display: flex;
            appearance: none;
            background: transparent;
            height: 24px;
            width: 24px;
            border: 2px solid #a6a6a6;
          }

          input[type="checkbox"] {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }

          input[type="checkbox"]:checked {
            accent-color: rgba(11, 4, 26, 0.8);
            border: 2px solid #00ff29;
            box-shadow: 0px 0px 4px 1px #00ff29;
            &::after {
              background-color: rgba(11, 4, 26, 0.8);
              // content: "✔";
              content: url("../../images/checkmark-white.svg");
              margin: auto;
              color: transparent;
            }
          }

          .undoIcon {
            position: absolute;
            right: 50px;
          }
        }
      }
    }

    .teamCards {
      margin-top: 0;
      margin-bottom: 42px;
    }

    .resetRoom {
      padding: 0 20px;
      .resetBtn {
        font-family: "Exo 2", sans-serif;
        position: relative;
        background: transparent;
        padding: 20px 30px;
        font-style: normal;
        letter-spacing: 0px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        width: 100%;
        cursor: pointer;
        margin: auto;

        &:hover {
          text-shadow: none;
        }

        &::before {
          position: absolute;
          content: "";
          height: 50px;
          width: 100%;
          left: 0;
          background: transparent;
          border: 1px solid #cf004b;
          box-shadow: 0px 0px 5px #cf004b;
          transform: skewX(145deg);
          border-top-left-radius: 5px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 5px;
        }

        &:hover::before {
          box-shadow: 0px 0px 5px #cf004b;
        }

        &:focus::before {
          background: rgba(83, 83, 83, 0.3);
        }
      }
    }
  }
}
