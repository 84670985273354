@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Exo 2", sans-serif;
}

html {
  scroll-behavior: smooth;
}

$blue: #462cff;
$red: #cf004b;
$offwhite: #e0e0e0;
$gray: #a6a6a6;
$secondgray: #767676;

.container {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (min-width: 992px) {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.animation {
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
    inset -1px 2px 9px 4px #cf004b;
  padding: 15px;
  // animation: 4s infinite glow;
  animation: blink linear infinite 4s;

  @keyframes blink {
    20% {
      box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
        inset -1px 2px 9px 4px #cf004b;
      border: 2px solid #ffffff;
    }
    21% {
      box-shadow: 0px 0px 9px 4px #462cff, 0px 0px 9px 4px #462cff,
        inset -1px 2px 9px 4px #462cff;
    }
    22% {
      border: 2px solid #fff;
    }
    23% {
      box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
        inset -1px 2px 9px 4px #cf004b;
      border: 2px solid #ffffff;
    }
    24% {
      box-shadow: 0px 0px 9px 4px #462cff, 0px 0px 9px 4px #462cff,
        inset -1px 2px 9px 4px #462cff;
      border: 2px solid #ffffff;
    }
    24% {
      box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
        inset -1px 2px 9px 4px #cf004b;
      border: 2px solid #ffffff;
    }
    25% {
      box-shadow: 0px 0px 9px 4px #462cff, 0px 0px 9px 4px #462cff,
        inset -1px 2px 9px 4px #462cff;
      border: 2px solid #ffffff;
    }
    26% {
      box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
        inset -1px 2px 9px 4px #cf004b;
      border: 2px solid #ffffff;
    }
    27% {
      box-shadow: 0px 0px 9px 4px #462cff, 0px 0px 9px 4px #462cff,
        inset -1px 2px 9px 4px #462cff;
      border: 2px solid #ffffff;
    }
    100% {
      border: 2px solid #ffffff;
      box-shadow: 0px 0px 9px 4px #462cff, 0px 0px 9px 4px #462cff,
        inset -1px 2px 9px 4px #462cff;
    }
  }
}

// Headers
h1 {
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1px;
  color: #fff;

  @media only screen and (min-width: 768px) {
    font-size: 64px;
    line-height: 77px;
  }
}

h2 {
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.75px;
  color: #fff;

  @media only screen and (min-width: 768px) {
    font-size: 48px;
    line-height: 58px;
  }
}

h3 {
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.75px;
  color: #fff;

  @media only screen and (min-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.625px;
  }
}

h4 {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.5px;
  color: #fff;
  @media only screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
}

h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #fff;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 21px;
  }
}

h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.75px;
}

// Paragraph
p {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.25px;
  color: $offwhite;

  @media only screen and (min-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
}

// Accordion

.teamCards {
  margin-top: 64px;
  .card {
    &:last-child {
      margin-bottom: 50px;
    }

    .MuiPaper-root {
      background-color: transparent;

      border: 1px solid #ffffff;
      box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
        inset -3px 4px 9px 0px #cf004b;
      margin-bottom: 24px;

      &:nth-child(2) {
        box-shadow: 0px 0px 9px 4px #0700cf, 0px 0px 9px 4px #0700cf,
          inset -3px 4px 9px 0px #0700cf;
      }

      &:nth-child(3) {
        box-shadow: 0px 0px 9px 4px #4ccf00, 0px 0px 9px 4px #4ccf00,
          inset -3px 4px 9px 0px #4ccf00;
      }

      .css-o4b71y-MuiAccordionSummary-content {
        margin: 0;
      }

      .MuiAccordionSummary-root {
        padding: 18px 30px;
      }

      .globalDropdown {
        & > div {
          margin: 0;

          svg {
            color: #fff !important;
            filter: drop-shadow(0px 0px 3px #ffffff) !important;
          }
        }
        .teamDropdown {
          display: flex;
          font-family: "Exo 2", sans-serif;
          flex-direction: column;
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: -0.625px;
          color: #fff;
          text-shadow: 0px 0px 17px #ffffff;
          margin: 0;

          span {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.28125px;
            color: #fff;
            text-shadow: none;
            margin-bottom: 6px;
          }
        }
      }

      .accordionDetails {
        padding: 0;

        .teamImageAccordion {
          padding: 0;

          .teamImage {
            border-top: 1px solid #322c3f;
            border-bottom: 1px solid #322c3f;

            .image {
              padding: 16px 30px;
              text-align: center;

              img {
                width: 100%;
                height: 227px;
                object-fit: cover;

                @media only screen and (min-width: 650px) {
                  height: 500px;
                }
              }
            }
          }

          .teamInfo {
            padding: 16px 30px;

            .roomFranchize,
            .location,
            .roomName,
            .difficultyLevel,
            .solvingFrame {
              span {
                font-family: "Exo 2", sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.28125px;
                color: #a6a6a6;
              }

              h5 {
                font-family: "Exo 2", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.75px;
                margin: 8px 0 24px;
                text-transform: inherit;
              }
            }
          }

          .kickTeam {
            padding: 24px 0px;
            border-top: 1px solid #322c3f;

            button {
              background-color: transparent;
              border: none;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
              display: flex;
              align-items: center;
              text-align: center;
              text-transform: uppercase;
              text-decoration: underline;
              color: #ffffff;
              text-shadow: 0px 0px 4px #ff0202;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

.myTeam {
  .MuiPaper-root {
    background-color: transparent;

    border: 1px solid #ffffff;
    box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
      inset -3px 4px 9px 0px #cf004b;
    margin-bottom: 24px;

    &:nth-child(2) {
      box-shadow: 0px 0px 9px 4px #0700cf, 0px 0px 9px 4px #0700cf,
        inset -3px 4px 9px 0px #0700cf;
    }

    &:nth-child(3) {
      box-shadow: 0px 0px 9px 4px #4ccf00, 0px 0px 9px 4px #4ccf00,
        inset -3px 4px 9px 0px #4ccf00;
    }

    .css-o4b71y-MuiAccordionSummary-content {
      margin: 0;
    }

    .MuiAccordionSummary-root {
      padding: 18px 30px;
    }

    .globalDropdown {
      & > div {
        margin: 0;

        svg {
          color: #fff !important;
          filter: drop-shadow(0px 0px 3px #ffffff) !important;
        }
      }
      .teamDropdown {
        display: flex;
        font-family: "Exo 2", sans-serif;
        flex-direction: column;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.625px;
        color: #fff;
        text-shadow: 0px 0px 17px #ffffff;
        margin: 0;

        span {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.28125px;
          color: #fff;
          text-shadow: none;
          margin-bottom: 6px;
        }
      }
    }

    .accordionDetails {
      padding: 0;

      .teamImageAccordion {
        padding: 0;

        .teamImage {
          border-top: 1px solid #322c3f;
          border-bottom: 1px solid #322c3f;

          .image {
            padding: 16px 30px;
            text-align: center;

            img {
              width: 100%;
            }
          }
        }

        .teamInfo {
          padding: 16px 30px;

          .roomFranchize,
          .location,
          .roomName,
          .difficultyLevel,
          .solvingFrame {
            span {
              font-family: "Exo 2", sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.28125px;
              color: #a6a6a6;
            }

            h5 {
              font-family: "Exo 2", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: -0.75px;
              margin: 8px 0 24px;
              text-transform: inherit;
            }
          }
        }

        .leaveMatch {
          padding: 24px 0;
          border-top: 1px solid #322c3f;
          button {
            background-color: transparent;
            border: none;
            font-family: "Exo 2", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            text-decoration: underline;
            color: #ffffff;
            text-shadow: 0px 0px 4px #ff0202;
            margin: auto;
          }
        }

        .kickTeam {
          padding: 24px 0;
          border-top: 1px solid #322c3f;

          button {
            background-color: transparent;
            border: none;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            text-decoration: underline;
            color: #ffffff;
            text-shadow: 0px 0px 4px #ff0202;
            margin: auto;
          }
        }
      }
    }
  }
}

// Pop up
.popupBackground {
  background: rgba(25, 15, 48, 0.6);
  backdrop-filter: blur(4px);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  cursor: pointer;
}

.matchPopup {
  // position: absolute;
  position: fixed;
  left: 0;
  right: 0;
  top: -20%;
  bottom: 0;
  margin: auto;
  background: #fafafa;
  box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
  transform: translate(0) !important;
  width: 90%;
  height: 300px;
  // height: fit-content;
  z-index: 4;

  @media only screen and (min-width: 600px) {
    width: 95%;
    height: 500px;
    top: -10%;
  }

  @media only screen and (min-width: 650px) {
    width: 95%;
    height: 500px;
    top: -20%;
  }

  @media only screen and (min-width: 1200px) {
    width: 30%;
    margin: auto;
  }

  .stayBtn {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .matchContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
      inset 1px 2px 4px 0px #cf004b;
    height: 100%;
    background: rgb(11 4 26);
    padding: 70px 24px;

    h2 {
      margin-bottom: 15px;
    }

    .leaveMatchTitle {
      margin-bottom: 48px;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.25px;
      color: #e8e8e8;
      margin-bottom: 26px;
    }

    .leaveMatch {
      font-family: "Exo 2", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      margin-top: 16px;
    }

    .btnLeaveMatch {
      background-color: transparent;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      margin-top: 15px;
      border: none;
    }

    .goBack {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      margin-top: 15px;
    }
  }
}
