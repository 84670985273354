.contact {
  text-align: right;
  position: relative;

  .contactLines {
    position: absolute;
    right: 0;
    top: -32%;
    z-index: 10;
    width: 100%;

    @media only screen and (min-width: 1200px) {
      top: -40%;
      width: auto;
    }
  }

  .image {
    width: 100%;

    @media only screen and (min-width: 1200px) {
      position: relative;
      z-index: 9;
    }
  }

  .backdrop {
    background: #0f0c16fc;
    backdrop-filter: blur(4px);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .popupCard {
      background-color: #ff0000;
      width: 90%;
      border-radius: 8px;
      padding: 40px 0;
      filter: drop-shadow(0 0 10px #ed297b);

      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 6px;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 10px;

        @media only screen and (min-width: 1200px) {
          font-size: 24px;
          line-height: 40px;
        }
      }

      p {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #fff;

        @media only screen and (min-width: 1200px) {
          font-size: 20px;
        }
      }

      @media only screen and (min-width: 1200px) {
        width: 450px;
      }
    }
  }

  .redLine {
    width: 90%;
    height: 10px;
    background-color: #ff0000;
    margin-top: -5px;
    margin-bottom: -10px;

    @media only screen and (min-width: 1400px) {
      width: 85%;
      height: 15px;
      margin-bottom: -15px;
      margin-left: -120px;
    }
  }

  .container {
    margin: 0 27px;
    position: relative;
    padding: 0;

    @media only screen and (min-width: 1200px) {
      margin: 0 120px 0 120px;
    }

    @media only screen and (min-width: 1800px) {
      margin: 0 150px 0 150px;
    }

    .line {
      position: absolute;
      left: 10%;
      z-index: -1;
      height: 1400px;

      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }

    .title {
      background: #ffffff;
      border: 1px solid #000000;
      padding: 20px 13px;
      width: 187px;
      text-transform: uppercase;
      margin-bottom: 25px;
      margin-left: 30px;

      @media only screen and (min-width: 992px) {
        border: 3px solid #000000;
        border-top: unset;
        padding: 40px 0;
        width: 650px;
        margin-bottom: 75px;
      }

      @media only screen and (min-width: 1200px) {
        padding: 50px 0;
        left: 48px;
        z-index: 1;
      }

      @media only screen and (min-width: 1600px) {
        width: 850px;
        padding: 60px 0;
      }

      h1 {
        text-align: center;
        letter-spacing: 1.65333px;

        @media only screen and (min-width: 992px) {
          letter-spacing: 6px;
        }
      }
    }

    .contactForm {
      width: 100%;
      text-align: center;
      padding-bottom: 25px;
      position: inherit;
      z-index: 11;

      @media only screen and (min-width: 992px) {
        padding-left: 50px;
        padding-bottom: 60px;
        width: 50%;
      }

      @media only screen and (min-width: 1200px) {
        padding-left: 170px;
        padding-right: 0px;
        padding-bottom: 75px;
        width: 40%;
      }

      @media only screen and (min-width: 1400px) {
        padding-left: 200px;
        padding-right: 50px;
      }

      @media only screen and (min-width: 1800px) {
        padding-left: 240px;
        padding-right: 80px;
      }

      .inputWrap {
        display: flex;
        flex-direction: column;
        gap: 7px;
        text-align: left;
        margin-bottom: 30px;

        @media only screen and (max-width: 768px) {
          margin-bottom: 20px;
        }

        label {
          margin-left: 10px;
          font-weight: 700;
          color: #000000;
          font-size: 10px;
          text-transform: uppercase;
          letter-spacing: 1px;

          @media only screen and (min-width: 992px) {
            font-size: 14px;
            letter-spacing: 2px;
          }
        }

        input {
          border: 1px solid #000000;
          height: 42px;
          outline: none;
          padding-left: 10px;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          border-radius: 0 !important;

          @media only screen and (min-width: 992px) {
            border: 2px solid #000000;
            font-size: 20px;
            height: 50px;
          }

          &::placeholder {
            color: #7d7d7d;
          }
        }

        .errorMessage {
          text-align: left;
          color: #cf004b;
          font-size: 12px;
          margin: 0;
          margin-top: -5px;
          margin-bottom: -5px;
          margin-left: 10px;

          @media only screen and (min-width: 1200px) {
            margin-top: -10px;
            margin-bottom: -10px;
          }
        }

        textarea {
          border: 1px solid #000000;
          outline: none;
          padding-left: 10px;
          padding-right: 10px;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          padding-top: 10px;
          font-family: "Exo 2", sans-serif;
          resize: none;
          width: 93% !important;
          border-radius: 0 !important;

          @media only screen and (min-width: 992px) {
            border: 2px solid #000000;
            font-size: 20px;
            width: 95% !important;
          }

          &::placeholder {
            color: #7d7d7d;
          }
        }
      }

      .submitButton {
        font-family: "Exo 2", sans-serif;
        position: relative;
        background: transparent;
        padding: 20px 30px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        border: none;
        width: 150px;
        cursor: pointer;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        filter: drop-shadow(0 0 10px #ed297b);
        padding-top: 25px;

        @media only screen and (min-width: 992px) {
          width: 311px;
        }

        span {
          color: #fff;
          z-index: 9;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 2px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;

          @media only screen and (min-width: 992px) {
            font-size: 18px;
          }
        }

        &:hover {
          text-shadow: none;
        }

        &::before {
          position: absolute;
          content: "";
          height: 50px;
          width: 100%;
          left: 0;
          background: red;
          color: #fff;
          box-shadow: 0px 0px 0px #ffffff;
          transform: skewX(145deg);
          // z-index: -1;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 5px;
        }

        &:hover::before {
          box-shadow: 0px 0px 17.2727px #ffffff;
        }

        &:focus::before {
          background: rgba(83, 83, 83, 0.3);
        }
      }

      .loaderContact {
        align-items: center;
        justify-content: center;
      }
    }
  }
}
