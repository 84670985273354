@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.section {
  height: 100vh;
  background-image: url("../../images/v.gif");
  animation-iteration-count: 3;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .slider {
    width: 100%;
    margin: auto;
  }

  @media only screen and (max-width: 1200px) {
    .slider {
      display: none;
    }
  }

  .slick-slider {
    overflow: hidden;
    max-width: 100vw;
    width: 100%;
  }

  .slide,
  .slide img {
    width: 600px;
    height: 450px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
      inset -1px 2px 9px 4px #cf004b;
  }

  .slide,
  .slide p {
    color: #fff;
    text-align: center;
  }

  .slide {
    opacity: 0.5;
    transform: scale(0.8);
    transition: 0.5s;
    filter: blur(5px);
    display: flex !important;
    align-items: center;
    gap: 100px;
    padding-left: 120px;
    border: none;
    box-shadow: none;
  }

  @media only screen and (min-width: 1700px) {
    .slide {
      gap: 290px;
    }
  }

  .slide-active {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
    width: 100%;
    display: flex !important;
    align-items: center;
    gap: 100px;
    padding-left: 190px;
    border: none;
    box-shadow: none;
  }

  @media only screen and (min-width: 1850px) {
    .slide-active {
      gap: 350px !important;
    }
  }

  @media only screen and (min-width: 1800px) and (max-width: 1850px) {
    .slide-active {
      gap: 270px !important;
    }
  }

  @media only screen and (min-width: 1700px) {
    .slide-active {
      gap: 200px;
      padding-left: 230px;
    }
  }

  @media only screen and (min-width: 1600px) {
    .slide-active {
      gap: 180px;
    }
  }

  .slick-dots {
    top: 55%;
    right: -20%;
  }

  @media only screen and (min-width: 1700px) {
    .slick-dots {
      right: -15%;
    }
  }

  @media only screen and (min-width: 1500px) {
    .slick-dots {
      right: -19% !important;
    }
  }

  @media only screen and (min-width: 1400px) {
    .slick-dots {
      right: -24%;
    }

    .slide-active {
      gap: 180px !important;
    }
  }

  @media only screen and (min-width: 1300px) {
    .slick-dots {
      right: -29%;
    }
  }

  .slick-dots li {
    width: 60px;
  }

  .slick-dots li button:before {
    color: #d9d9d9;
    background-color: #d9d9d9;
    box-shadow: 0px 0px 6px 1px #ffffff;
    border-radius: 30px;
    width: 60px;
    height: 3px;
    font-size: 0;
  }

  .slick-dots li.slick-active button:before {
    color: #d9d9d9;
    background-color: #d9d9d9;
    box-shadow: 0px 0px 6px 1px #ffffff;
    border-radius: 30px;
    width: 60px;
    height: 3px;
    font-size: 0;
  }
}

// Mobile slider
.mobileSlider {
  @media only screen and (min-width: 1200px) {
    display: none;
  }

  h5 {
    margin: 30px 0 0px;
  }

  h2 {
    margin-bottom: 15px;
  }

  .line {
    border-bottom: 2px solid #ffffff;
    box-shadow: 0px 0px 4px 3px #462cff;
    width: 100px;
    margin: 5px 0 15px;
    animation: 4s infinite line;
  }

  .slick-initialized .slick-slide {
    height: 520px;
  }
  .slick-slide img {
    width: 264px;
    height: 190px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
      inset -1px 2px 9px 4px #cf004b;
    padding: 5px;
    animation: blink linear infinite 4s;
  }

  .slick-slide {
    opacity: 0.1;

    p,
    h5,
    h2,
    .line {
      display: none;
    }

    img {
      margin-top: 10px;
      margin-left: 20px;
      width: 220px;
      animation: none;
    }
  }

  .slick-active {
    opacity: 1;

    p,
    h5,
    h2,
    .line {
      display: block;
    }

    img {
      width: 264px;
      margin: 0px;
    }
  }

  .slick-dots {
    bottom: -5px;
    right: 0;
    top: unset;
  }
}
