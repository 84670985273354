.profileScreen {
  background-image: url("../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 1200px) {
    height: 100%;
    gap: 0;
  }

  .roomPopup {
    height: fit-content;
    max-height: 500px;
    width: 50%;
    top: -10%;
    bottom: 0;

    .matchContent {
      box-shadow: 0px 0px 9px 4px #fff, 0px 0px 9px 4px #fff,
        inset 1px 2px 4px 0px #fff !important;
    }

    @media only screen and (max-width: 1200px) {
      width: 90%;
      height: fit-content;
      max-height: 400px;
    }

    .closePopup {
      position: absolute;
      top: 40px;
      right: 40px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    .matchContent {
      justify-content: flex-start;
      padding: 40px 24px;
      height: fit-content;
    }

    .roomsSections {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: #fff;
      width: 100%;
      overflow: scroll;
      height: fit-content;
      max-height: 500px;

      @media only screen and (max-width: 1200px) {
        max-height: 400px;
      }

      .roomContent {
        border-bottom: 1px solid #ffffff17;
        padding-bottom: 20px;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        h4 {
          font-size: 20px;
          color: #ababab;
          span {
            color: #fff;
          }
        }
      }
    }
  }

  .cardInfoPopup {
    position: relative;
    box-shadow: 0px 0px 9px 4px #fff, 0px 0px 9px 4px #fff,
      inset 1px 2px 4px 0px #fff !important;
    .cardInfoImage {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    .totalPayExplane {
      margin-bottom: 40px;
      @media only screen and (max-width: 600px) {
        margin-bottom: 20px !important;
      }
    }
  }

  .popupBackground {
    background: rgba(25, 15, 48, 0.6);
    backdrop-filter: blur(4px);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }

  .popUp {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    bottom: 0;
    margin: auto;
    background: rgba(11, 4, 26, 0.8);
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 9px 4px #cf004b;
    transform: translate(0) !important;
    border-radius: 10px;
    margin: 0 20px;
    padding: 0 20px;
    z-index: 4;
    height: fit-content;

    @media only screen and (min-width: 1200px) {
      width: 35%;
      margin: auto;
      top: unset;
      bottom: unset;
      height: 100%;
      overflow: scroll;
    }

    h2 {
      text-align: center;
    }

    p {
      text-align: center;
      margin-bottom: 45px;
    }

    .info {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.28125px;
        color: #fff;
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 227px;
        margin-top: 10px;
        object-fit: cover;
        color: #fff;

        @media only screen and (min-width: 650px) {
          height: 500px;
        }
      }

      .errorMessage {
        text-align: left;
        color: #cf004b;
        margin-top: 10px;
        font-size: 12px;
        margin-bottom: 0;
      }

      .popupInfo {
        margin: 0;
        text-align: left;
        text-shadow: 0px 0px 17px #ffffff;
      }
    }

    .goBack {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: transparent;
      border: none;
      color: #fff;
      text-decoration: underline;
      text-transform: uppercase;
      margin-top: 24px;
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      cursor: pointer;
    }
  }

  .topSection {
    margin-top: 40px;
    h3 {
      text-align: center;
      margin-top: 10px;

      @media only screen and (max-width: 1200px) {
        font-size: 25px !important;
        line-height: 30px !important;
        margin-top: 20px;
      }
    }
  }

  .middleSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 40px;

    @media only screen and (max-width: 1200px) {
      margin: 0 30px;
    }

    hr {
      width: 98%;
      height: 1px;
      background-color: rgba(204, 204, 204, 0.289);
      border: none;
    }

    .infoSection {
      background-color: transparent;
      border: 1px solid #ffffff;
      box-shadow: 0px 0px 9px 4px #462cff, 0px 0px 9px 4px #462cff,
        inset -3px 4px 9px 0px #462cff;
      border-radius: 5px;
      width: 100%;
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @media only screen and (max-width: 1200px) {
        flex-direction: column;
      }

      h3 {
        font-size: 30px;
        line-height: 0;
        margin-bottom: 15px;

        @media only screen and (max-width: 1200px) {
          font-size: 25px;
        }
      }

      .infoName {
        display: flex;
        flex-direction: column;
        margin: 20px 0 20px 20px;
        align-items: flex-start;
        gap: 20px;
        // width: 30%;

        @media only screen and (max-width: 1200px) {
          margin: 0;
          margin-top: 20px;
          align-items: center;
          width: 100%;
        }
      }

      .infoCompanyName {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        align-items: center;
        gap: 20px;
      }

      .infoTimeZone {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        align-items: center;
        gap: 20px;

        @media only screen and (max-width: 1200px) {
          margin: 0 0 20px;
        }
      }

      .infoAddress {
        display: flex;
        flex-direction: column;
        margin: 20px 20px 20px 0;
        align-items: flex-end;
        gap: 20px;

        // width: 30%;

        @media only screen and (max-width: 1200px) {
          margin: 0;
          margin-bottom: 20px;
          align-items: center;
          width: 100%;
        }

        .infoAddressContent {
          display: flex;
          gap: 10px;
          cursor: pointer;
        }

        h3 {
          display: flex;
          align-items: unset;
          gap: 10px;
        }

        img {
          transform: rotate(270deg);
        }
      }
    }

    .subscriptionSection {
      background-color: transparent;
      border: 1px solid #ffffff;
      box-shadow: 0px 0px 9px 4px #462cff, 0px 0px 9px 4px #462cff,
        inset -3px 4px 9px 0px #462cff;
      border-radius: 5px;
      width: 100%;

      @media only screen and (max-width: 1200px) {
        // padding-bottom: 20px;
      }

      h2 {
        text-align: center;
        margin-top: 30px;

        @media only screen and (max-width: 1200px) {
          font-size: 25px;
        }
      }

      .bodyText {
        width: 50%;
        color: #fff !important;
        margin: auto;
        text-align: center;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 50px;
        font-size: 22px;
        line-height: 30px;

        @media only screen and (max-width: 1200px) {
          width: 80%;
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 35px;
          line-height: 22px;
        }

        span {
          font-size: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-weight: bold;

          @media only screen and (max-width: 1200px) {
            font-size: 16px;
          }
        }
      }

      .billing {
        width: 50%;
        color: #fff !important;
        margin: auto;
        text-align: center;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 50px;
        font-size: 16px;

        @media only screen and (max-width: 1200px) {
          width: 80%;
          font-size: 14px;
          margin-top: 10px;
          margin-bottom: 35px;
        }

        a {
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;
        }
      }

      .subscribeCards {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 20px 40px;
        gap: 30px;

        @media only screen and (max-width: 1200px) {
          margin: 0 20px 25px;
        }

        label {
          &:nth-child(1) {
            height: fit-content;
          }

          &:nth-child(2) {
            height: fit-content;
          }

          &:nth-child(3) {
            height: 600px;
            margin-top: -40px;
            display: flex;
            align-items: center;
            gap: 16px;
            // justify-content: center;

            @media only screen and (max-width: 1200px) {
              height: 100%;
              margin-top: 0;
            }

            @media only screen and (min-width: 1600px) {
              height: 700px;
            }

            .btn {
              margin: unset;

              @media only screen and (max-width: 1200px) {
                margin-top: -18px;
              }
            }
          }
        }

        @media only screen and (max-width: 1200px) {
          flex-direction: column;
          gap: 40px;
        }

        .proStyle {
          // position: absolute;
          // top: -7%;
          // left: 0;
          text-transform: uppercase;
          color: #74cf00;
          font-weight: bold;
          // text-shadow: 0px 0px 7px #fff, -1px 0px 6px #fff, 0px 1px 2px #fff,
          //   0 0 40px #fff;
          font-size: 16px;

          @media only screen and (max-width: 1200px) {
            // display: none;
            font-size: 14px;
            margin-bottom: -10px;
          }
        }

        .subscribeCardWrap {
          position: relative;
          // width: 45%;
          width: 600px;
          background-color: transparent;
          border: 1px solid #ffffff;
          border-radius: 5px;
          padding: 20px 100px;
          transition: box-shadow 0.3s ease;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 20px;
          cursor: pointer;

          &:nth-child(1) {
            .button {
              color: #cf004b;
            }

            .button::before {
              border: 1px solid #cf004b !important;
            }
          }

          &:nth-child(2) {
            .button {
              color: #ffc42c;
            }

            .button::before {
              border: 1px solid #ffc42c !important;
            }
          }

          &:nth-child(3) {
            .button {
              color: #74cf00;
            }

            .button::before {
              border: 1px solid #74cf00 !important;
            }

            .matchNumberBorder {
              p {
                font-size: 32px;

                @media only screen and (max-width: 1200px) {
                  font-size: 20px;
                }
              }
            }
          }

          .btn {
            margin: auto;
            width: 100%;
            text-align: center;

            .button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 90%;

              @media only screen and (max-width: 1200px) {
                width: 80%;
              }
            }
          }

          .ribbon {
            position: absolute;
            top: 0;
            right: 0;
            width: 150px;

            @media only screen and (max-width: 1200px) {
              width: 120px;
            }
          }

          @media only screen and (max-width: 1200px) {
            width: 100%;
            flex-direction: column;
            padding: 20px 0;
            gap: 0px;
          }

          &:nth-child(1) {
            box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
              inset -3px 4px 9px 0px #cf004b;

            &:hover {
              box-shadow: 0px 0px 12px 6px #cf004b, 0px 0px 12px 6px #cf004b,
                inset -3px 4px 12px 0px #cf004b;
            }

            .radioInput {
              border-color: #cf004b; /* Red color for border */
            }

            .radioInput:checked {
              border-color: #cf004b; /* Red color when checked */
              background-color: #cf004b; /* Red color for background when checked */
              box-shadow: 0 0 10px #cf004b, 0 0 20px #cf004b, 0 0 30px #cf004b; /* Red glow */
            }
          }

          &:nth-child(2) {
            box-shadow: 0px 0px 9px 4px #ffc42c, 0px 0px 9px 4px #ffc42c,
              inset -3px 4px 9px 0px #ffc42c;

            &:hover {
              box-shadow: 0px 0px 12px 6px #ffc42c, 0px 0px 12px 6px #ffc42c,
                inset -3px 4px 12px 0px #ffc42c;
            }

            .radioInput {
              border-color: #ffc42c; /* Blue color for border */
            }

            .radioInput:checked {
              border-color: #ffc42c; /* Blue color when checked */
              background-color: #ffc42c; /* Blue color for background when checked */
              box-shadow: 0 0 10px #ffc42c, 0 0 20px #ffc42c, 0 0 30px #ffc42c; /* Blue glow */
            }
          }

          &:nth-child(3) {
            box-shadow: 0px 0px 9px 4px #74cf00, 0px 0px 9px 4px #74cf00,
              inset -3px 4px 9px 0px #74cf00;

            &:hover {
              box-shadow: 0px 0px 12px 6px #74cf00, 0px 0px 12px 6px #74cf00,
                inset -3px 4px 12px 0px #74cf00;
            }

            .radioInput {
              border-color: #74cf00; /* Blue color for border */
            }

            .radioInput:checked {
              border-color: #74cf00; /* Blue color when checked */
              background-color: #74cf00; /* Blue color for background when checked */
              box-shadow: 0 0 10px #74cf00, 0 0 20px #74cf00, 0 0 30px #74cf00; /* Blue glow */
            }
          }

          .radioInput {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 25px;
            width: 25px;
            border: 2px solid #cf004b;
            border-radius: 50%;
            transition: border-color 0.3s ease, background-color 0.3s ease;
            background-color: transparent;

            @media only screen and (max-width: 1200px) {
              margin: 25px 0 10px 20px;
            }
          }

          .cardContent {
            text-align: left;
            color: #ffffff;
            width: 100%;

            .listSection {
              display: flex;
              flex-direction: row;

              @media only screen and (max-width: 1200px) {
                flex-direction: column;
                gap: 0;

                ul {
                  &:nth-child(2) {
                    margin-top: 10px;
                  }

                  li {
                    font-size: 16px;
                  }
                }
              }
            }

            @media only screen and (max-width: 1200px) {
              padding: 0 20px 20px 20px;
              width: auto;
            }

            .matchNumberBorder {
              padding: 10px;
              box-shadow: 0px 0px 1px 1px #fff, 0px 0px 8px 3px #fff,
                0px 1px 2px 0px #fff;
              margin: 20px 0;
              text-align: center;
              min-height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              @media only screen and (max-width: 1200px) {
                min-height: auto;
              }

              // @media only screen and (max-width: 1200px) {
              //   padding: 0;
              //   box-shadow: unset;
              //   margin: 0;
              //   text-align: left;
              // }
            }

            ul {
              // padding-left: 30px;
              margin-top: 30px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              list-style-type: none;

              @media only screen and (max-width: 1200px) {
                // padding-left: 20px;
                gap: 10px;
                margin-top: 20px;
              }
              li {
                color: #fff;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                font-size: 22px;

                img {
                  width: 10%;
                }
              }
            }
          }

          .cardTitle {
            margin: 0;
            margin-top: -10px;
            color: #ffffff;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            width: 100%;

            h3 {
              font-weight: bold;

              @media only screen and (max-width: 1200px) {
                font-size: 25px;
                text-align: center;
                line-height: 32px;
              }
            }

            @media only screen and (max-width: 1200px) {
              gap: 20px;
              margin-top: -2px;
            }

            .priceCard {
              @media only screen and (max-width: 1200px) {
                position: absolute;
                top: 15px;
                right: 20px;
              }
            }
          }

          .cardContent p {
            color: #fff;
          }

          input[type="radio"]:checked + .cardContent h3 {
            color: #ffffff;
          }
        }

        .payed {
          position: relative;
          // width: 45%;
          width: 600px;
          background-color: transparent;
          border: 1px solid #ffffff;
          border-radius: 5px;
          padding: 20px;
          transition: box-shadow 0.3s ease;
          display: flex;
          align-items: flex-start;
          gap: 20px;
          cursor: pointer;
          pointer-events: none;
          flex-direction: column;

          &:nth-child(1) {
            .button {
              color: #cf004b;
            }

            .button::before {
              border: 1px solid #cf004b !important;
            }
          }

          &:nth-child(2) {
            .button {
              color: #ffc42c;
            }

            .button::before {
              border: 1px solid #ffc42c !important;
            }
          }

          &:nth-child(3) {
            .button {
              color: #74cf00;
            }

            .button::before {
              border: 1px solid #74cf00 !important;
            }
          }

          .btn {
            margin: auto;
            width: 100%;
            text-align: center;

            @media only screen and (max-width: 1200px) {
              margin-top: unset !important;
            }

            .button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 90%;

              @media only screen and (max-width: 1200px) {
                width: 80%;
              }
            }

            .payedButton {
              opacity: 0.4;
            }
          }

          .ribbon {
            position: absolute;
            top: 0;
            right: 0;
            width: 150px;

            @media only screen and (max-width: 1200px) {
              width: 120px;
            }
          }

          @media only screen and (max-width: 1200px) {
            width: 100%;
            flex-direction: column;
            padding: 20px 0;
            gap: 20px;
          }

          &:nth-child(1) {
            box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
              inset -3px 4px 9px 0px #cf004b;

            &:hover {
              box-shadow: 0px 0px 12px 6px #cf004b, 0px 0px 12px 6px #cf004b,
                inset -3px 4px 12px 0px #cf004b;
            }

            .radioInput {
              border-color: #cf004b; /* Red color for border */
            }

            .radioInput:checked {
              border-color: #cf004b; /* Red color when checked */
              background-color: #cf004b; /* Red color for background when checked */
              box-shadow: 0 0 10px #cf004b, 0 0 20px #cf004b, 0 0 30px #cf004b; /* Red glow */
            }
          }

          &:nth-child(2) {
            box-shadow: 0px 0px 9px 4px #ffc42c, 0px 0px 9px 4px #ffc42c,
              inset -3px 4px 9px 0px #ffc42c;

            &:hover {
              box-shadow: 0px 0px 12px 6px #ffc42c, 0px 0px 12px 6px #ffc42c,
                inset -3px 4px 12px 0px #ffc42c;
            }

            .radioInput {
              border-color: #ffc42c; /* Blue color for border */
            }

            .radioInput:checked {
              border-color: #ffc42c; /* Blue color when checked */
              background-color: #ffc42c; /* Blue color for background when checked */
              box-shadow: 0 0 10px #ffc42c, 0 0 20px #ffc42c, 0 0 30px #ffc42c; /* Blue glow */
            }
          }

          &:nth-child(3) {
            box-shadow: 0px 0px 9px 4px #74cf00, 0px 0px 9px 4px #74cf00,
              inset -3px 4px 9px 0px #74cf00;

            &:hover {
              box-shadow: 0px 0px 12px 6px #74cf00, 0px 0px 12px 6px #74cf00,
                inset -3px 4px 12px 0px #74cf00;
            }

            .radioInput {
              border-color: #74cf00; /* Blue color for border */
            }

            .radioInput:checked {
              border-color: #74cf00; /* Blue color when checked */
              background-color: #74cf00; /* Blue color for background when checked */
              box-shadow: 0 0 10px #74cf00, 0 0 20px #74cf00, 0 0 30px #74cf00; /* Blue glow */
            }
          }

          .radioInput {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 25px;
            width: 25px;
            border: 2px solid #cf004b;
            border-radius: 50%;
            transition: border-color 0.3s ease, background-color 0.3s ease;
            background-color: transparent;
            opacity: 0.4;

            @media only screen and (max-width: 1200px) {
              margin: 25px 0 10px 20px;
            }
          }

          .cardContent {
            text-align: left;
            color: #ffffff;
            width: 100%;
            opacity: 0.4;

            // @media only screen and (max-width: 1200px) {
            //   padding: 0 20px 20px 20px;
            // }

            .justGreenPack {
              min-height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              p {
                font-size: 32px;

                @media only screen and (max-width: 1200px) {
                  font-size: 20px;
                }
              }
            }

            .matchNumberBorder {
              padding: 10px;
              box-shadow: 0px 0px 1px 1px #fff, 0px 0px 8px 3px #fff,
                0px 1px 2px 0px #fff;
              margin: 20px 0;
              text-align: center;

              @media only screen and (max-width: 1200px) {
                margin: 20px;
                min-height: unset !important;
              }

              // @media only screen and (max-width: 1200px) {
              //   padding: 0;
              //   box-shadow: unset;
              //   margin: 0;
              //   text-align: left;
              // }
            }

            ul {
              // padding-left: 30px;
              margin-top: 30px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              list-style-type: none;

              @media only screen and (max-width: 1200px) {
                // padding-left: 20px;
                gap: 10px;
                margin-top: 20px;
                padding: 0 20px;
              }
              li {
                color: #ababab;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                img {
                  width: 5%;
                }
              }
            }
          }

          .cardTitle {
            margin: 0;
            margin-top: -10px;
            color: #ffffff;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            width: 100%;
            font-weight: bold;

            @media only screen and (max-width: 1200px) {
              gap: 20px;
              margin-top: -2px;
            }

            .priceCard {
              text-align: center;
              @media only screen and (max-width: 1200px) {
                position: absolute;
                top: 15px;
                right: 20px;
              }
            }
          }

          .cardContent p {
            color: #fff;
          }

          input[type="radio"]:checked + .cardContent h3 {
            color: #ffffff;
          }
        }
      }
      .welcomeScreenBtnDisabled {
        opacity: 0.5;
      }

      span {
        color: #ababab;
        text-align: center;
        width: 100%;
        display: block;
        font-size: 14px;
        margin-top: 10px;

        @media only screen and (max-width: 1200px) {
          width: 90%;
          margin: auto;
          margin-top: 10px;
        }
      }
    }
  }

  .bottomSection {
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 40px;

    a {
      text-transform: uppercase;
      color: #fff;
      text-shadow: -3px 0px 16px #fff, -2px 4px 9px #fff, 3px 1px 2px #e60073,
        0 0 40px #e60073;
      font-size: 14px;
      text-decoration: none;
    }
  }

  .footerProfile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 60px 40px;

    .contSupport {
      text-transform: uppercase;
      color: #fff;
      text-shadow: -2px 0px 2px #e60073, 0 0 8px #e60073;
      font-size: 16px;
      text-decoration: none;
      text-decoration: none;
      margin-left: -80px;
    }
    .logoutLink {
      text-decoration: none;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
    }

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  .footerProfileMobile {
    display: none;

    @media only screen and (max-width: 1200px) {
      display: block;
      margin: 20px 40px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      text-align: center;
      text-transform: uppercase;
    }

    .contSupport {
      text-transform: uppercase;
      color: #fff;
      text-shadow: -2px 0px 2px #e60073, 0 0 8px #e60073;
      font-size: 16px;
      text-decoration: none;
      text-decoration: none;
    }

    .logoutLink {
      color: #fff;
      text-decoration: none;
    }
  }
}
