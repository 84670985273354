.arenaDo {
  position: relative;

  .containerImages {
    .image {
      width: 100%;
      margin-bottom: -1px;
    }

    @media only screen and (min-width: 1200px) {
      margin: 0 147px 0 150px;
    }

    @media only screen and (min-width: 1800px) {
      margin: 0 180px 0 190px;
    }

    @media only screen and (min-width: 1200px) {
      position: relative;
      z-index: 1;
    }
  }

  // .image {
  //   width: 100%;
  //   margin-bottom: -1px;

  //   @media only screen and (min-width: 1200px) {
  //     position: relative;
  //     z-index: 1;
  //   }

  //   @media only screen and (min-width: 1400px) {
  //     width: 79%;
  //     margin-left: 147px;
  //   }

  //   @media only screen and (min-width: 1450px) {
  //     width: 79%;
  //     margin-left: 158px;
  //   }

  //   @media only screen and (min-width: 1500px) {
  //     width: 79%;
  //     margin-left: 167px;
  //   }

  //   @media only screen and (min-width: 1550px) {
  //     width: 79%;
  //     margin-left: 179px;
  //   }

  //   @media only screen and (min-width: 1600px) {
  //     width: 79%;
  //     margin-left: 189px;
  //   }

  //   @media only screen and (min-width: 1650px) {
  //     width: 79%;
  //     margin-left: 198px;
  //   }

  //   @media only screen and (min-width: 1700px) {
  //     width: 79%;
  //     margin-left: 209px;
  //   }

  //   @media only screen and (min-width: 1750px) {
  //     width: 79%;
  //     margin-left: 220px;
  //   }

  //   @media only screen and (min-width: 1800px) {
  //     margin-left: 202px !important;
  //   }

  //   @media only screen and (min-width: 1850px) {
  //     margin-left: 212px !important;
  //   }

  //   @media only screen and (min-width: 1900px) {
  //     margin-left: 225px !important;
  //   }
  // }

  .redLine {
    width: 90%;
    height: 10px;
    background-color: #ff0000;
    margin-top: -3px;
    margin-bottom: -10px;
    float: right;

    @media only screen and (min-width: 768px) {
      width: 95%;
    }

    @media only screen and (min-width: 1400px) {
      height: 15px;
      margin-bottom: -15px;
      width: 80%;
      margin-left: auto;
    }
    @media only screen and (min-width: 1800px) {
      height: 15px;
      width: 80%;
      margin-left: auto;
    }
  }

  .container {
    margin: 0 27px;
    position: relative;
    padding: 0;

    @media only screen and (min-width: 1200px) {
      margin: 0 120px 0 120px;
    }

    @media only screen and (min-width: 1800px) {
      margin: 0 150px 0 150px;
    }

    .line {
      position: absolute;
      right: 10%;
      z-index: 0;

      @media only screen and (max-width: 1200px) {
        display: none;
      }

      @media only screen and (min-width: 1800px) {
        height: 1400px;
        display: block;
      }
    }

    .title {
      background: #ffffff;
      border: 1px solid #000000;
      padding: 20px 13px;
      width: 187px;
      position: absolute;
      // top: -35px;
      right: 26px;
      margin-bottom: 25px;
      top: -4px;
      left: 30px;

      @media only screen and (min-width: 992px) {
        border: 3px solid #000000;
        border-top: unset;
        width: 650px;
        padding: 50px 0;
        top: -3px;
        left: unset;
      }

      @media only screen and (min-width: 1200px) {
        padding: 50px 0;

        z-index: 1;
      }

      @media only screen and (min-width: 1600px) {
        width: 850px;

        padding: 60px 0;
      }

      h1 {
        text-align: center;
        letter-spacing: 1.65333px;

        @media only screen and (min-width: 992px) {
          letter-spacing: 6px;
        }
      }
    }

    .list {
      list-style-type: none;
      list-style-image: url("../../images/triangleBlueMobile.svg");
      padding-left: 20px;
      padding-right: 15px;
      margin-bottom: 30px;
      padding-top: 80px;

      @media only screen and (min-width: 992px) {
        list-style-image: url("../../images/triangleBlueMiddle.svg");
        margin-bottom: 50px;
        padding-top: 100px;
      }
      @media only screen and (min-width: 1600px) {
        list-style-image: url("../../images/triangleBlueMiddle.svg");
      }

      @media only screen and (min-width: 992px) {
        padding-left: 50px;
        margin-bottom: 60px;
        padding-top: 180px;
      }

      @media only screen and (min-width: 1200px) {
        padding-left: 0px;
        padding-right: 220px;
        margin-bottom: 86px;
        padding-top: 215px;
      }

      @media only screen and (min-width: 1400px) {
        padding-left: 110px;
        padding-right: 320px;
      }

      @media only screen and (min-width: 1600px) {
        padding-top: 250px;
      }

      @media only screen and (min-width: 1800px) {
        padding-left: 150px;
        padding-right: 400px;
      }

      li {
        margin-bottom: 14px;

        p {
          margin-left: 10px;
        }

        @media only screen and (min-width: 992px) {
          p {
            margin-left: 22px;
          }
        }

        @media only screen and (min-width: 1600px) {
          p {
            margin-left: 28px;
          }
        }

        @media only screen and (min-width: 1200px) {
          margin-bottom: 24px;
        }

        @media only screen and (min-width: 1600px) {
          margin-bottom: 32px;
        }
      }
    }
  }
}
