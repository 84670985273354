.banerSection {
  position: relative;
  background-image: url("../../images/baner_Mobile.jpg");
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media only screen and (min-width: 993px) {
    background-image: url("../../images/baner.jpg");
  }

  .imagesGif {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 0;
    margin-top: 40px;
    margin-bottom: 20px;

    // .gif {
    //   width: 255px;
    //   height: 191px;
    //   mix-blend-mode: exclusion;
    //   text-align: right;
    //   margin-left: 200px;
    //   margin-bottom: -50px;
    // }

    // .gifSecond {
    //   width: 255px;
    //   height: 191px;
    //   mix-blend-mode: exclusion;
    //   transform: rotate(180deg);
    //   margin-left: -200px;
    //   margin-top: -50px;
    // }

    .lines {
      display: flex;
      align-items: center;

      .leftLine {
        animation: 0.2s ease-out 0s 1 slideInLeft;
        margin-right: -5px;

        @keyframes slideInLeft {
          0% {
            transform: translateX(-100%);
          }

          100% {
            transform: translateX(0px);
          }
        }
      }

      .rightLine {
        animation: 0.2s ease-out 0s 1 slideInRight;
        margin-left: -5px;

        @keyframes slideInRight {
          0% {
            transform: translateX(100%);
          }

          100% {
            transform: translateX(0px);
          }
        }
      }
    }
  }

  h1 {
    margin-top: 22px;

    @media only screen and (min-width: 993px) {
      width: 70%;
    }

    @media only screen and (min-width: 1200px) {
      width: 65%;
    }

    @media only screen and (min-width: 1300px) {
      width: 60%;
    }

    @media only screen and (min-width: 1400px) {
      width: 55%;
    }

    @media only screen and (min-width: 1500px) {
      width: 50%;
    }

    @media only screen and (min-width: 1600px) {
      width: 45%;
    }

    @media only screen and (min-width: 1700px) {
      width: 40%;
    }
  }

  // .btn-scroll {
  //   width: 28px;
  //   height: 40px;
  //   background: transparent;
  //   border: 1px solid #fff;
  //   border-radius: 10px;
  //   margin-top: 80px;
  //   cursor: pointer;

  //   &:hover {
  //     box-shadow: 0px 0px 4px #ffffff;
  //   }
  // }

  .center-con {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
  }

  .round {
    position: absolute;
    border: 1px solid #fff;
    width: 28px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    background: transparent;

    &:hover {
      box-shadow: 0px 0px 4px #ffffff;
    }
  }

  #cta {
    width: 100%;
    cursor: pointer;

    // &::before {
    //   content: url("../../images/icons/Icon_Arrow.svg");
    //   position: absolute;
    // top: 5px;
    // }
  }

  .arrow {
    position: absolute;
    bottom: 0;
    margin-left: 0px;
    width: 12px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 5px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .next {
    background-image: url("../../images/icons/Icon_Arrow.svg");
  }

  @keyframes bounceAlpha {
    0% {
      opacity: 1;
      transform: translateY(0px) scale(1);
    }
    25% {
      opacity: 0;
      transform: translateY(10px) scale(0.9);
    }
    26% {
      opacity: 0;
      transform: translateY(-10px) scale(0.9);
    }
    55% {
      opacity: 1;
      transform: translateY(0px) scale(1);
    }
  }

  .bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .arrow.primera.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .round:hover .arrow {
    background-image: url("../../images/scroll.svg");
    background-size: cover;
    width: 100%;
    height: auto;
    margin-top: -5px;
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .round:hover .arrow.primera {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
