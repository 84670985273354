.soloMatches {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 40px 24px;
  position: relative;

  .matchLobby {
    margin: 50px 0 30px;
    text-align: center;
  }

  .switchToSolo {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: transparent;
    border: none;
    margin: auto;
    margin-top: 16px;
    text-decoration: underline;
    cursor: pointer;
  }

  .downBtn {
    margin-bottom: 100px;
  }

  .yourTeam {
    margin: 48px 0 24px;
    text-align: center;
  }
}
