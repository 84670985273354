.revolutionizing {
  position: relative;

  .line {
    position: absolute;
    left: 15%;
    top: 0;
    height: 800px;
    z-index: -1;

    @media only screen and (min-width: 1500px) {
      height: 800px;
    }

    @media only screen and (min-width: 1600px) {
      height: 1200px;
    }

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  .redLine {
    width: 90%;
    height: 10px;
    background-color: #ff0000;
    margin-top: -5px;
    margin-bottom: -11px;

    @media only screen and (min-width: 1400px) {
      width: 80%;
      height: 15px;
      margin-bottom: -15px;
      margin-top: -5px;
    }

    @media only screen and (min-width: 1800px) {
      width: 80%;
    }
  }

  .containerGrid {
    margin: 0 27px;

    @media only screen and (min-width: 1200px) {
      margin: 0 250px 0 100px;
    }

    @media only screen and (min-width: 1800px) {
      margin: 0 400px 0 190px;
    }

    .title {
      background: #ffffff;
      border: 1px solid #000000;
      padding: 12px 13px;
      width: 187px;
      margin-bottom: 25px;
      z-index: 9;
      position: inherit;
      margin-left: 30px;

      @media only screen and (min-width: 992px) {
        border: 3px solid #000000;
        border-top: unset;
        width: 650px;
        margin-bottom: 75px;
        margin-left: 0;
      }

      @media only screen and (min-width: 1200px) {
        padding: 30px 0;
        left: 48px;
      }

      @media only screen and (min-width: 1600px) {
        width: 850px;
      }

      h1 {
        text-align: center;
        letter-spacing: 1.65333px;

        @media only screen and (min-width: 992px) {
          letter-spacing: 6px;
        }
      }
    }

    .list {
      list-style-type: none;
      list-style-image: url("../../images/triangleRedMobile.svg");
      padding-left: 20px;
      padding-right: 15px;
      margin-bottom: 30px;

      @media only screen and (min-width: 992px) {
        list-style-image: url("../../images/triangleRedMiddle.svg");
      }
      @media only screen and (min-width: 1600px) {
        list-style-image: url("../../images/triangleRed.svg");
      }

      @media only screen and (min-width: 992px) {
        padding-left: 50px;
        margin-bottom: 60px;
      }

      @media only screen and (min-width: 1200px) {
        padding-left: 170px;
        padding-right: 0px;
        margin-bottom: 75px;
      }

      @media only screen and (min-width: 1400px) {
        padding-left: 200px;
        padding-right: 50px;
      }

      @media only screen and (min-width: 1800px) {
        padding-left: 200px;
        padding-right: 80px;
      }

      li {
        margin-bottom: 14px;

        p {
          margin-left: 10px;
        }

        @media only screen and (min-width: 992px) {
          p {
            margin-left: 22px;
          }
        }

        @media only screen and (min-width: 1600px) {
          p {
            margin-left: 28px;
          }
        }

        @media only screen and (min-width: 1200px) {
          margin-bottom: 24px;
        }

        @media only screen and (min-width: 1600px) {
          margin-bottom: 32px;
        }
      }
    }
  }
}
