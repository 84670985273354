.pagination {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 20px;
  margin-bottom: 35px;

  button {
    background: transparent;
    border: none;
    color: #fff;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: #767676;
    width: 16px;

    &.active {
      border-bottom: 2px solid #ffffff;
      text-shadow: 0px 0px 17px #ffffff;
      color: #fff;
      padding-bottom: 6px;
    }
  }
}
