.roomWrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  .dots {
    position: absolute;
    right: 0;
    width: 60%;
    top: 10%;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  .line {
    width: 1154px;
    height: 15px;
    position: absolute;
    background-color: #0089cf;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .box {
    margin-left: 203px;
    border: 3px solid #000;
    border-top: none;
    width: 637px;
    background-color: #fff;
    position: relative;
    z-index: 9;
    margin-bottom: 70px;

    @media only screen and (max-width: 768px) {
      margin: 0 0 60px 20px;
      width: 289px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      margin: 0 0 60px 20px;
      width: 400px;
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: 6px;
      text-align: center;
      color: #000000;
      text-transform: uppercase;
      padding: 35px 19px;
      font-family: "TipoTypeHomeBold";

      @media only screen and (max-width: 1200px) {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 6px;
        text-align: center;
        padding: 20px;
      }
    }
  }

  .boxList {
    padding: 0 320px 0 377px;

    @media only screen and (max-width: 1200px) {
      padding: 0 20px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-bottom: 40px;
      list-style-image: url("../../images/red_triangle.svg");

      @media only screen and (max-width: 1200px) {
        gap: 30px;
        margin-left: 20px;
        margin-bottom: 30px;
      }

      li {
        padding-left: 25px;

        @media only screen and (max-width: 1200px) {
          padding-left: 10px;
        }

        h5 {
          font-size: 24px;
          font-weight: 700;
          line-height: 40px;
          letter-spacing: 6px;
          text-align: left;
          color: #0089cf;
          margin-bottom: 16px;
          font-family: "TipoTypeHomeBold";

          @media only screen and (max-width: 1200px) {
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 6px;
            text-align: left;
            margin-bottom: 10px;
          }
        }

        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          text-align: left;
          color: #000000;
          font-family: "TipoTypeHomeRegular";

          @media only screen and (max-width: 1200px) {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
          }

          span {
            color: #ff0000;
          }
        }

        &:nth-child(3) {
          p {
            width: 90%;

            @media only screen and (max-width: 1200px) {
              width: 100%;
            }
          }
        }
      }
    }

    .contactUs {
      position: relative;
      background: transparent;
      padding: 20px 30px;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      font-weight: 700;
      letter-spacing: 2px;
      font-size: 16px;
      line-height: 21px;
      border: none;
      width: 300px;
      min-width: fit-content;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 70px;
      filter: drop-shadow(0 0 10px #0089cf);

      // &.wordlWide {
      //   width: 270px;

      //   @media only screen and (max-width: 768px) {
      //     width: 90%;
      //     margin: auto;
      //     margin-bottom: 60px;
      //   }

      //   @media only screen and (min-width: 768px) and (max-width: 1200px) {
      //     width: 270px;
      //   }
      // }

      @media only screen and (max-width: 768px) {
        width: 90%;
        margin: auto;
        margin-bottom: 60px;
      }

      @media only screen and (min-width: 768px) and (max-width: 1200px) {
        width: 300px;
        margin: unset;
        margin-left: 20px;
        margin-bottom: 60px;
      }

      &:hover {
        text-shadow: none;
      }

      span {
        color: #fff;
        z-index: 9;
        font-size: 18px;
        font-weight: bold;
        line-height: 27.34px;
        letter-spacing: 2px;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        font-family: "TipoTypeHomeBold";

        @media only screen and (max-width: 1200px) {
          font-size: 14px;
          font-weight: 700;
          line-height: 21.27px;
          letter-spacing: 2px;
          text-align: center;
        }
      }

      &::before {
        position: absolute;
        content: "";
        height: 48px;
        width: 100%;
        left: 0;
        background: #0089cf;
        border: 1px solid #0089cf;
        box-shadow: 0px 0px 0px #0089cf;
        transform: skewX(145deg);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 5px;

        @media only screen and (max-width: 1200px) {
          height: 41px;
        }
      }

      &:hover::before {
        box-shadow: 0px 0px 17.2727px #ffffff;
      }

      // &:focus::before {
      //   background: rgba(83, 83, 83, 0.3);
      // }
    }
  }

  .banerImage {
    width: 100%;
  }
}
