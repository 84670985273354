.arenaWork {
  .image {
    width: 100%;

    @media only screen and (min-width: 1200px) {
      position: relative;
      z-index: 1;
    }
  }

  .redLine {
    width: 90%;
    height: 10px;
    background-color: #ff0000;
    margin-top: -5px;
    margin-bottom: -10px;

    @media only screen and (min-width: 1400px) {
      width: 85%;
      height: 15px;
      margin-bottom: -15px;
      margin-left: -120px;
    }
  }

  .work {
    margin: 0 27px;
    position: relative;
    @media only screen and (min-width: 1200px) {
      margin: 0 250px 0 100px;
    }

    @media only screen and (min-width: 1800px) {
      margin: 0 400px 0 190px;
    }

    .line {
      position: absolute;
      left: 10%;
      z-index: -1;

      @media only screen and (max-width: 1200px) {
        display: none;
      }

      @media only screen and (min-width: 1600px) {
        height: 1700px;
      }
    }

    .title {
      background: #ffffff;
      border: 1px solid #000000;
      padding: 20px 13px;
      width: 187px;
      text-transform: uppercase;
      margin-bottom: 24px;
      margin-left: 30px;

      @media only screen and (min-width: 992px) {
        border: 3px solid #000000;
        border-top: unset;
        padding: 40px 0;
        width: 650px;
        margin-bottom: 75px;
      }

      @media only screen and (min-width: 1200px) {
        padding: 50px 0;
        left: 48px;
        z-index: 1;
      }

      @media only screen and (min-width: 1600px) {
        width: 850px;
        padding: 60px 0;
      }

      h1 {
        text-align: center;
        letter-spacing: 1.65333px;

        @media only screen and (min-width: 992px) {
          letter-spacing: 6px;
        }
      }
    }

    .list {
      list-style-type: none;
      list-style-image: url("../../images/triangleBlueMobile.svg");
      padding-left: 20px;
      padding-right: 15px;
      margin-bottom: 30px;

      @media only screen and (min-width: 992px) {
        list-style-image: url("../../images/triangleBlueMiddle.svg");
      }
      @media only screen and (min-width: 1600px) {
        list-style-image: url("../../images/triangleBlueMiddle.svg");
      }

      @media only screen and (min-width: 992px) {
        padding-left: 50px;
        margin-bottom: 60px;
      }

      @media only screen and (min-width: 1200px) {
        padding-left: 170px;
        padding-right: 0px;
        margin-bottom: 86px;
      }

      @media only screen and (min-width: 1400px) {
        padding-left: 200px;
        padding-right: 50px;
      }

      @media only screen and (min-width: 1800px) {
        padding-left: 200px;
        padding-right: 80px;
      }

      h1 {
        color: #ff0000;
        margin-bottom: 14px;
        margin-left: 10px;

        @media only screen and (max-width: 768px) {
          margin-bottom: 6px;
        }
      }

      p {
        margin-bottom: 20px;
        margin-left: 10px;

        @media only screen and (min-width: 992px) {
          margin-bottom: 30px;
          margin-left: 22px;
        }

        @media only screen and (min-width: 1600px) {
          margin-left: 28px;
        }
      }

      li {
        margin-bottom: 14px;

        p {
          margin-left: 10px;
        }

        @media only screen and (min-width: 992px) {
          p,
          h1 {
            margin-left: 22px;
          }
        }

        @media only screen and (min-width: 1600px) {
          p,
          h1 {
            margin-left: 28px;
          }
        }

        @media only screen and (min-width: 1200px) {
          margin-bottom: 24px;
        }

        @media only screen and (min-width: 1600px) {
          margin-bottom: 32px;
        }
      }
    }
  }
}
