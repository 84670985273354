.stripeForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #fff;

  @media only screen and (max-width: 600px) {
    gap: 10px;
  }

  .card-element {
    background-color: rgba(176, 173, 173, 0.118);
    border-bottom: 2px solid #fff;
    padding: 10px;
  }

  h1 {
    color: #fff;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
  }

  input[type="text"] {
    font-size: 14px;
    width: 100%;
    padding: 16px 20px;
    margin: 8px 0 0px 0;
    display: inline-block;
    background: #16131a;
    border: none;
    font-family: "Exo 2", sans-serif;
    border: 1px solid transparent;
    border-radius: 5px;
    box-sizing: border-box;
    color: #fff;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &.time {
      width: 25%;

      @media only screen and (min-width: 360px) {
        width: 30%;
      }
    }

    &::placeholder-shown {
      font-family: "Exo 2", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.28125px;
      color: #535353;
    }

    &::-webkit-calendar-picker-indicator {
      display: none;

      @media only screen and (min-width: 1200px) {
        display: block !important;
        filter: invert(1);
      }
    }

    &:focus {
      background: transparent;
      border: 1px solid #ffffff;
      box-shadow: 0px 0px 10px #ffffff;
      border-radius: 5px;
    }

    &:not(:placeholder-shown) {
      border: 1px solid transparent;
      border-radius: 5px;
      height: 50px;

      &:focus {
        border: 1px solid #ffffff;
        box-shadow: 0px 0px 10px #ffffff;
      }
    }
  }

  input {
    color: #fff !important;
  }

  .stripeButton {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loaderSpiner {
    justify-content: center;
    margin-top: 20px;
  }

  .oneTimeFee {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    font-size: 16px;
    font-weight: bold;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
      bottom: 50px;
    }
  }

  .monthlyTotal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    font-size: 16px;
    font-weight: bold;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
      bottom: 30px;
    }
  }

  .fullTotal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    font-size: 16px;
    font-weight: bold;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
      bottom: 10px;
    }
  }

  .shortExplanation {
    font-size: 14px;
    margin-left: 5px;
    font-style: italic;

    @media only screen and (max-width: 600px) {
      font-size: 10px;
    }
  }
}
