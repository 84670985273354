.faqSection {
  // height: 100vh;
  width: 100%;
  padding-top: 0px;

  @media only screen and (min-width: 1100px) {
    height: 100vh;
    padding-top: 150px;
  }

  .faq {
    display: flex;
    flex-direction: column;
    margin: 100px 0;

    @media only screen and (min-width: 1100px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 100px;
      margin: 0;
    }

    h3 {
      margin-bottom: 35px;
    }

    .accordions {
      @media only screen and (min-width: 1100px) {
        width: 600px;
      }

      @media only screen and (min-width: 1600px) {
        width: 800px;
      }

      .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        &:nth-child(odd) {
          background: rgba(10, 10, 10, 0.5) !important;
          border: 2px solid #ffffff;
          box-shadow: 0px 0px 9px 4px #cf004b, 0px 0px 9px 4px #cf004b,
            inset -1px 2px 9px 4px #cf004b;
          animation: 4s infinite acc;
        }

        &:nth-child(even) {
          background: rgba(10, 10, 10, 0.5) !important;
          border: 2px solid #ffffff;
          box-shadow: 0px 0px 9px 4px #462cff, 0px 0px 9px 4px #462cff,
            inset -1px 2px 9px 4px #462cff;
          animation: 4s infinite acc;
        }
      }

      .MuiPaper-root {
        margin-bottom: 20px;
        background: rgba(74, 74, 74, 0.2);
        backdrop-filter: blur(6.72px);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // .Mui-expanded {
        //   background: red !important;
        // }

        .MuiAccordionSummary-root {
          padding: 0 25px;
        }

        .MuiAccordionDetails-root {
          padding: 0 25px 25px 25px;
        }

        .MuiTypography-root {
          font-family: "Exo 2", sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          letter-spacing: -0.28125px;
          color: #fff;
        }

        .MuiSvgIcon-root {
          color: #fff;
          filter: drop-shadow(0px 0px 3px #ffffff);
        }
      }
    }

    .faqImage {
      display: none;

      @media only screen and (min-width: 1100px) {
        display: block;
        padding: 15px;

        img {
          width: 100%;
        }
      }
    }
  }
}
