.winnerDisplay {
  background-image: url("../../images/winnerBackground.png");
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;

  .firstPlace {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 80px;
    position: relative;

    .shadow {
      background: linear-gradient(
        14.63deg,
        #030206 24.44%,
        rgba(6, 8, 23, 0.05) 87.65%
      );
      height: 700px;
      width: 900px;
      left: 35px;
      position: absolute;
      border-radius: 5px;
    }

    img {
      padding: 25px 30px;
      width: 900px;
      height: 700px;
      border: 5px solid #ffc701;
      box-shadow: 0px 0px 17.1786px #ffc701;
      border-radius: 5px;
    }

    .teamScoreInfo {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-bottom: 90px;
      height: 700px;
      left: 112px;

      .score {
        margin-bottom: 40px;
      }

      h1,
      h5 {
        text-shadow: 0px 0px 14px #ffffff;
      }

      h5 {
        margin-bottom: 8px;
      }
    }
  }

  .otherPlaces {
    width: 50%;
    height: 100px;
    z-index: 1;
    display: inline-block;
    animation: move 20s linear infinite;
    white-space: nowrap;
    .placeCard {
      position: relative;
      background: transparent;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      text-shadow: 0px 0px 14px #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      width: 800px;
      height: 150px;
      cursor: pointer;
      margin: auto;
      margin-bottom: 32px;

      &:nth-child(2) {
        &::before {
          border: 5px solid #74cf00;
          box-shadow: 0px 0px 17.1786px #74cf00;
        }
      }

      &:nth-child(3) {
        &::before {
          border: 5px solid #cf00ae;
          box-shadow: 0px 0px 17.1786px #cf00ae;
        }
      }

      &:nth-child(4) {
        &::before {
          border: 5px solid #cf004b;
          box-shadow: 0px 0px 17.1786px #cf004b;
        }
      }

      &:nth-child(5) {
        &::before {
          border: 5px solid #0021cf;
          box-shadow: 0px 0px 17.1786px #0021cf;
        }
      }

      &:nth-child(6) {
        &::before {
          border: 5px solid #9859ff;
          box-shadow: 0px 0px 17.1786px #9859ff;
        }
      }

      &:nth-child(7) {
        &::before {
          border: 5px solid #d3fffd;
          box-shadow: 0px 0px 17.1786px #d3fffd;
        }
      }

      &:nth-child(8) {
        &::before {
          border: 5px solid #ff9900;
          box-shadow: 0px 0px 17.1786px #ff9900;
        }
      }

      &:nth-child(9) {
        &::before {
          border: 5px solid #74cf00;
          box-shadow: 0px 0px 17.1786px #74cf00;
        }
      }

      &:nth-child(10) {
        &::before {
          border: 5px solid #cf00ae;
          box-shadow: 0px 0px 17.1786px #cf00ae;
        }
      }

      &:nth-child(11) {
        &::before {
          border: 5px solid #ff9900;
          box-shadow: 0px 0px 17.1786px #ff9900;
        }
      }

      &:nth-child(12) {
        &::before {
          border: 5px solid #74cf00;
          box-shadow: 0px 0px 17.1786px #74cf00;
        }
      }

      &:nth-child(13) {
        &::before {
          border: 5px solid #cf00ae;
          box-shadow: 0px 0px 17.1786px #cf00ae;
        }
      }

      &:nth-child(14) {
        &::before {
          border: 5px solid #cf004b;
          box-shadow: 0px 0px 17.1786px #cf004b;
        }
      }

      &:nth-child(15) {
        &::before {
          border: 5px solid #0021cf;
          box-shadow: 0px 0px 17.1786px #0021cf;
        }
      }

      &:nth-child(16) {
        &::before {
          border: 5px solid #9859ff;
          box-shadow: 0px 0px 17.1786px #9859ff;
        }
      }

      &:nth-child(17) {
        &::before {
          border: 5px solid #d3fffd;
          box-shadow: 0px 0px 17.1786px #d3fffd;
        }
      }

      .teamPlace {
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        line-height: 58px;
        margin-left: 60px;
        z-index: 1;
      }

      .nameOfTeam {
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        line-height: 58px;
        letter-spacing: -0.75px;
        width: 55%;
        z-index: 1;
      }

      .teamScore {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-right: 60px;
        z-index: 1;

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
        }

        p {
          font-style: normal;
          font-weight: 300;
          font-size: 48px;
          line-height: 58px;
        }
      }

      &::before {
        position: absolute;
        content: "";
        height: 150px;
        width: 100%;
        left: 0;
        background: #000;
        z-index: 0;

        transform: skewX(145deg);
        // z-index: -1;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 5px;
        border: 5px solid #ff9900;
        box-shadow: 0px 0px 17.1786px #ff9900;
      }
    }

    @keyframes move {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-2000px);
      }
    }
  }

  .otherPlacesNoAnimation {
    width: 50%;
    margin-left: -100px;
    z-index: 1;
    display: inline-block;
    white-space: nowrap;
    .placeCard {
      position: relative;
      background: transparent;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      text-shadow: 0px 0px 14px #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      width: 800px;
      height: 150px;
      cursor: pointer;
      margin: auto;
      margin-bottom: 32px;

      &:nth-child(2) {
        &::before {
          border: 5px solid #74cf00;
          box-shadow: 0px 0px 17.1786px #74cf00;
        }
      }

      &:nth-child(3) {
        &::before {
          border: 5px solid #cf00ae;
          box-shadow: 0px 0px 17.1786px #cf00ae;
        }
      }

      &:nth-child(4) {
        &::before {
          border: 5px solid #cf004b;
          box-shadow: 0px 0px 17.1786px #cf004b;
        }
      }

      &:nth-child(5) {
        &::before {
          border: 5px solid #0021cf;
          box-shadow: 0px 0px 17.1786px #0021cf;
        }
      }

      &:nth-child(6) {
        &::before {
          border: 5px solid #9859ff;
          box-shadow: 0px 0px 17.1786px #9859ff;
        }
      }

      &:nth-child(7) {
        &::before {
          border: 5px solid #d3fffd;
          box-shadow: 0px 0px 17.1786px #d3fffd;
        }
      }

      .teamPlace {
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        line-height: 58px;
        margin-left: 60px;
        z-index: 1;
      }

      .nameOfTeam {
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        line-height: 58px;
        letter-spacing: -0.75px;
        width: 55%;
        z-index: 1;
      }

      .teamScore {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-right: 60px;
        z-index: 1;

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
        }

        p {
          font-style: normal;
          font-weight: 300;
          font-size: 48px;
          line-height: 58px;
        }
      }

      &::before {
        position: absolute;
        content: "";
        height: 150px;
        width: 100%;
        left: 0;
        background: #000;
        z-index: 0;

        transform: skewX(145deg);
        // z-index: -1;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 5px;
        border: 5px solid #ff9900;
        box-shadow: 0px 0px 17.1786px #ff9900;
      }
    }
  }
}
