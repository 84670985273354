.welcomeScreen {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: fixed;

  .content {
    padding: 0px 24px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (min-width: 1200px) {
      width: 35%;
      margin: auto;
    }

    h2 {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 16px;
    }

    p {
      text-align: center;
      color: #fff;
    }

    a {
      text-decoration: none;
    }

    .btn {
      .button {
        margin: auto;
        margin-top: 25px;
      }
    }

    .welcomeScreenBtn {
      width: 80%;
      display: flex;
      justify-content: center;
    }
  }
}
